import Layout from "components/Layout";
import TriviaMain from "components/Trivia/TriviaMain";
import styled from "styled-components";

const TriviaPage = () => {
  return (
    <TriviaPageRoot id="trivia-page-root">
      <Layout>
        <TriviaContainer id="trivia-page-container">
          <TriviaMain />
        </TriviaContainer>
      </Layout>
    </TriviaPageRoot>
  );
};

const TriviaPageRoot = styled.div`
  height: 100vh;
  width: 100vw;
`;

const TriviaContainer = styled.div`
  padding-top: 64px;
  height: calc(100% - 64px);
  width: 100%;
`;

export default TriviaPage;
