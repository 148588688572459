import React, { useState } from "react";
import { Button } from "react-bootstrap";
import DifficultySelection from "./components/DifficultySelection";
import Categories from "./components/Categories";
import { TriviaCategoryProps } from "./TriviaMain";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp } from "@fortawesome/free-solid-svg-icons";
type Props = {
  currentCategory: TriviaCategoryProps | null;
  categories: TriviaCategoryProps[];
  setCurrentCategory: (category: TriviaCategoryProps) => void;
  selectedDifficulty: string;
  selectDifficulty: (difficulty: "easy" | "medium" | "hard" | "") => void;
  getQuestion: () => void;
  handleNextStep: () => void;
  getQuestionPointValue: (
    difficulty: "easy" | "medium" | "hard" | ""
  ) => number;
  doublePointsAvailable: boolean;
  triggerDoublePoints: () => void;
};
const CategorySelect = ({
  currentCategory,
  categories,
  setCurrentCategory,
  selectedDifficulty,
  selectDifficulty,
  getQuestion,
  handleNextStep,
  getQuestionPointValue,
  triggerDoublePoints,
  doublePointsAvailable,
}: Props) => {
  const [hasActivatedDoublePoints, setHasActivatedDoublePoints] =
    useState(false);

  const onActivateDoublePoints = () => {
    setHasActivatedDoublePoints((p) => {
      if (!p) triggerDoublePoints();
      return !p;
    });
  };

  const handleNext = () => {
    getQuestion();
    handleNextStep();
  };

  return (
    <>
      {!currentCategory?.name && (
        <Categories
          categories={categories}
          setSelectedCategory={setCurrentCategory}
        />
      )}
      {currentCategory?.name && (
        <DifficultyContainer>
          <TriviaCategoryText>{currentCategory?.name}</TriviaCategoryText>
          <DifficultySelection
            selectedDifficulty={selectedDifficulty}
            selectDifficulty={selectDifficulty}
            getQuestionPointValue={getQuestionPointValue}
            hasActivatedDoublePoints={hasActivatedDoublePoints}
          />
          {doublePointsAvailable && (
            <DoubleButton
              variant="light"
              className={hasActivatedDoublePoints ? "enabled" : ""}
              onClick={onActivateDoublePoints}
            >
              <FontAwesomeIcon icon={faAnglesUp} />
              {hasActivatedDoublePoints
                ? " Double Points Activated "
                : " Double Points Available "}
              <FontAwesomeIcon icon={faAnglesUp} />
            </DoubleButton>
          )}
          <ContinueButton
            variant="light"
            disabled={selectedDifficulty === ""}
            onClick={handleNext}
          >
            Start
          </ContinueButton>
        </DifficultyContainer>
      )}
    </>
  );
};

const DifficultyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 64px;
  height: 100%;
  width: 100%;
  max-width: 400px;
  .enabled {
    border: 2px solid black;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;
const TriviaCategoryText = styled.div`
  font-family: "Tilt Neon";
  text-align: center;
  font-size: 24px;
  margin: 12px 0 32px;
  padding: 12px 24px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0 0 16px;
    padding: 0;

    width: 90%;
  }
`;

// const ButtonContainer = styled.div`
//   display: flex;
// justify-content: center;
//   width: 100%;
// `;
const ContinueButton = styled(Button)`
  margin-top: 24px;
  padding: 12px 48px;
  font-size: 24px;
  width: 100%;
  font-family: "Luckiest Guy";
`;
const DoubleButton = styled(Button)`
  margin-top: 24px;
  padding: 12px 48px;
  width: 100%;
`;
// const BackButton = styled(Button)`
//   font-family: "Tilt Neon";
//   margin-top: 24px;
// `;

export default CategorySelect;
