import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  handleNextStep: () => void;
}

const Intro = ({ handleNextStep }: Props) => {
  return (
    <Container>
      <Title className="text-animation">Trivia Time</Title>
      <div>
        <Subtitle>
          Welcome to the Trivia Time! Choose a category and difficulty, answer
          10 questions within the time limit, but don't miss more than three.
        </Subtitle>
        <Subtitle>Win to earn points to spend on future items!</Subtitle>
      </div>
      <StartButton variant="light" onClick={handleNextStep}>
        Get Started
      </StartButton>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 64px;
  @media (max-width: 768px) {
    padding-bottom: 16px;
  }
`;
const Title = styled.div`
  font-size: 64px;
  font-family: "Luckiest Guy";
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 32px;
  }
`;
const Subtitle = styled.div`
  font-size: 28px;
  font-family: "Tilt Neon";
  max-width: 600px;
  text-align: center;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    font-size: 18px;
  }
`;
const StartButton = styled(Button)`
  margin-top: 24px;
  padding: 12px 48px;
  font-size: 28px;
  width: 100%;
  font-family: "Luckiest Guy";
  @media (max-width: 768px) {
    font-size: 18px;
    width: 90%;
  }
`;

export default Intro;
