import { useAuth } from "context/Auth";
import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
} from "react";
// import { useAuth } from "context/Auth";

import { useUnityContext } from "react-unity-webgl";
import { UnityProvider } from "react-unity-webgl/distribution/types/unity-provider";

// import { useLessthanBreakpoint } from "hooks";

declare global {
  interface Window {
    receiveUnityMessage: any;
  }
}

interface UnityMessageData {
  type: string;
  action: string;
  data: string;
}

export interface HasLoadedState {
  scene: boolean;
  avatar: boolean;
  interactions: boolean;
  server: boolean;
}

export const UnityCtx = createContext({});

interface UnityContextInterface {
  unityProvider: UnityProvider;
  isLoaded: boolean;
  loadingProgression: number;
  takeUnityScreenshot: () => void;
  sendMessage: (
    gameObjectName: string,
    methodName: string,
    parameter?: any
  ) => void;
  unload: () => Promise<void>;
  currentScene: string;
  setCurrentScene: (scene: string) => void;
  sendAvatarURL: (avatarModelURL: string) => void;
  sendArenaCustomizations: () => void;
  isLoading: boolean;
  hasLoadedState: HasLoadedState;
}

const parseDataString: (data: any) => UnityMessageData = (data: any) => {
  if (typeof data === "string") {
    return JSON.parse(data);
  } else {
    return data as UnityMessageData;
  }
};

const UnityStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const IS_DEV_ENV = process.env.REACT_APP_ENV === "dev";
  const { playerData } = useAuth();
  // const hasTriggeredMobileControls = useRef(false);

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
    takeScreenshot,
    sendMessage,
    unload,
  } = useUnityContext({
    loaderUrl: IS_DEV_ENV
      ? process.env.PUBLIC_URL + "/unity/Build.loader.js"
      : process.env.REACT_APP_UNITY_LOADER!,
    dataUrl: IS_DEV_ENV
      ? process.env.PUBLIC_URL + "/unity/Build.data"
      : process.env.REACT_APP_UNITY_DATA!,
    frameworkUrl: IS_DEV_ENV
      ? process.env.PUBLIC_URL + "/unity/Build.framework.js"
      : process.env.REACT_APP_UNITY_FRAMEWORK!,
    codeUrl: IS_DEV_ENV
      ? process.env.PUBLIC_URL + "/unity/Build.wasm"
      : process.env.REACT_APP_UNITY_WASM!,
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  // const isMediumScreen = useLessthanBreakpoint("md");
  const [hasLoadedState, setHasLoadedState] = useState<HasLoadedState>({
    scene: false,
    avatar: false,
    server: false,
    interactions: true,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [currentScene, setCurrentScene] = useState("loading");
  // const [canRecieveMessages, setCanRecieveMessages] = useState(false);

  const handleSendUnityMessage = useCallback(
    (gameObjectName: string, methodName: string, parameter?: any) => {
      console.log("[REACT] Sending Message to Unity: ", {
        gameObjectName,
        methodName,
        parameter,
      });

      sendMessage(gameObjectName, methodName, parameter);
    },
    [sendMessage]
  );

  const handleNextSceneLoading = (previousScene: string) => {
    console.log(
      "[REACT]: Next scene loading, current scene is:",
      previousScene
    );

    setHasLoadedState((prev) => ({
      ...prev,
      scene: false,
      avatar: false,
      network: false,
    }));
    setIsLoading(true);
  };

  const handleLoadingState = useCallback(
    (nextState: { [key: string]: boolean }) => {
      console.log("[REACT] Loading State: ", nextState);
      setHasLoadedState((prev) => {
        console.log("[REACT] Has Loaded State: ", { ...prev, ...nextState });
        if (
          Object.values({ ...prev, ...nextState }).every((val) => val === true)
        ) {
          setIsLoading(false);
        }
        return { ...prev, ...nextState };
      });
    },
    [setHasLoadedState, setIsLoading]
  );

  const sendAvatarURL = useCallback(
    (avatarModelURL: string) => {
      handleSendUnityMessage(
        "GameManager",
        "ReactToUnity",
        JSON.stringify({
          type: "avatar",
          action: "load",
          data: avatarModelURL,
        })
      );
    },
    [handleSendUnityMessage]
  );

  const handleSceneLoaded = useCallback(
    (sceneName: string) => {
      console.log("[REACT]: Scene Loaded: ", sceneName);
      setCurrentScene(sceneName);
      // this is not triggering correctly, playerdata seems to be null
      if (sceneName === "BaseScene") {
        console.log(
          "[REACT]: Base Scene detected, setting up custom items: ",
          playerData
        );

        sendAvatarURL(playerData?.avatarModelURL!);
        handleLoadingState({ scene: true, server: true });
      }
      if (sceneName === "MainScene") {
        handleLoadingState({ scene: true });
        sendAvatarURL(playerData?.avatarModelURL!);
      }
    },
    [playerData, setCurrentScene, sendAvatarURL, handleLoadingState]
  );

  const handleAvatarLoaded = useCallback(() => {
    console.log("[REACT]: Avatar Loaded: ");
    handleLoadingState({ avatar: true, scene: true });
  }, [handleLoadingState]);

  const handleNetworkMessaqge = useCallback(
    (action: string, data: string) => {
      if (action === "joined")
        switch (data.toLowerCase()) {
          case "shared":
            console.log("[REACT] Player joined to shared instance");
            handleLoadingState({ network: true });
            sendAvatarURL(playerData?.avatarModelURL!);
            break;
          case "single":
            console.log("[REACT] Player joined single instance");
            break;
        }
    },
    [playerData, handleLoadingState, sendAvatarURL]
  );

  // const sendMobileControls = useCallback(
  //   (mobileState: boolean) => {
  //     sendMessage(
  //       "GameManager",
  //       mobileState ? "EnableMobileControls" : "DisableMobileControls",
  //       ""
  //     );
  //   },
  //   [sendMessage]
  // );

  const handleUnityMessageType = useCallback(
    ({
      type,
      action,
      data,
    }: {
      type: string;
      action: string;
      data: string;
    }) => {
      switch (type) {
        case "loaded":
          if (action === "scene") {
            handleSceneLoaded(data);
          }
          if (action === "avatar") {
            handleAvatarLoaded();
          }
          break;
        case "unloaded":
          handleNextSceneLoading(data);
          break;
        case "network":
          console.log("Network Message: ", action, data);
          handleNetworkMessaqge(action, data);
          break;
        default:
          break;
      }
    },
    [handleAvatarLoaded, handleNetworkMessaqge, handleSceneLoaded]
  );

  const handleRecievedUnityMessage = useCallback(
    (_message: string) => {
      const parsedData: { type: string; action: string; data: string } =
        parseDataString(_message);
      console.log("Item Logged in [RecieveMessageService]", parsedData);
      handleUnityMessageType(parsedData);
    },
    [handleUnityMessageType]
  );

  function takeUnityScreenshot() {
    const dataUrl = takeScreenshot("image/jpg", 0.5);
    var image = new Image();
    image.src = dataUrl!;
    var w = window.open("");
    w?.document.write(image.outerHTML);

    // var link = document.createElement('a');
    // link.download = "unity-screenshot.jpg";
    // link.href = dataUrl!;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    // window.open(dataUrl, '_blank');
  }

  useEffect(() => {
    addEventListener("receiveUnityMessage", handleRecievedUnityMessage);
    if (window.receiveUnityMessage)
      window.receiveUnityMessage = handleRecievedUnityMessage;
    return () => {
      removeEventListener("receiveUnityMessage", handleRecievedUnityMessage);
    };
  }, [addEventListener, removeEventListener, handleRecievedUnityMessage]);

  // useEffect(() => {
  //   const checkFocus = () => {
  //     if (document.hasFocus()) {
  //       console.log("Document has focus");
  //     } else {
  //       console.log("Document does not have focus");
  //     }
  //   };
  //   const focusInterval = setInterval(checkFocus, 10000);
  //   return () => {
  //     clearInterval(focusInterval);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (
  //     currentScene === "characterselect" && // maybe change this to check if just not in default state
  //     playerData?.avatarModelURL &&
  //     hasSentAvatarURL.current === false
  //   ) {
  //     hasSentAvatarURL.current = true;
  //     console.log("Sending avatar url: ", playerData?.avatarModelURL);
  //     sendAvatarURL(playerData?.avatarModelURL);
  //   }

  //   if (
  //     currentScene === "characterselect" &&
  //     isMediumScreen &&
  //     hasTriggeredMobileControls.current === false
  //   ) {
  //     hasTriggeredMobileControls.current = true;
  //     sendMobileControls(true);
  //   }
  // }, [
  //   currentScene,
  //   playerData?.avatarModelURL,
  //   sendAvatarURL,
  //   isMediumScreen,
  //   sendMobileControls,
  // ]);

  return (
    <UnityCtx.Provider
      value={{
        unityProvider,
        isLoaded,
        hasLoadedState,
        loadingProgression,
        isLoading,
        takeUnityScreenshot,
        sendMessage: handleSendUnityMessage,
        unload,
        currentScene,
        setCurrentScene,
        sendAvatarURL,
      }}
    >
      {children}
    </UnityCtx.Provider>
  );
};

export const useUnity = () => {
  return useContext(UnityCtx) as UnityContextInterface;
};

export default UnityStateProvider;
