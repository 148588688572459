import React from "react";
import styled, { useTheme } from "styled-components";
import { useAudio } from "context/AudioContext/AudioContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeDown,
  faVolumeUp,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import { IColorOptions } from "styled";

const GlobalVolumeSlider = () => {
  const theme = useTheme();

  // const colorMap = {
  //   primary: theme.palette.common.primary,
  //   "trivia-primary":
  //     theme.palette.common[
  //       "trivia-primary" as keyof typeof theme.palette.common
  //     ],
  // };

  // const color = "primary";

  const { volume, setVolume } = useAudio();
  const onVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVolume(parseInt(e.target.value));
  };
  return (
    <GlobalVolumeContainer $theme={theme.palette.common.primary}>
      <StyledVolumeDown icon={volume === 0 ? faVolumeMute : faVolumeDown} />
      <GlobalVolumeSliderInput
        type="range"
        min={0}
        max={100}
        value={volume}
        onChange={onVolumeChange}
      ></GlobalVolumeSliderInput>
      <StyledVolumeUp icon={faVolumeUp} />
    </GlobalVolumeContainer>
  );
};

const GlobalVolumeContainer = styled.div<{ $theme: IColorOptions }>`
  display: flex;
  align-items: center;
  margin: 0 16px;

  /********** Range Input Styles **********/
  /*Range Reset*/
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 200px;
  }

  /* Removes default focus */
  input[type="range"]:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: ${(props) => props.$theme.dark};
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -4px; /* Centers thumb on the track */

    /*custom styles*/
    background-color: ${(props) => props.$theme.main};
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }

  input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid ${(props) => props.$theme.dark};
    outline: 3px solid ${(props) => props.$theme.dark};
    outline-offset: 0.125rem;
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: ${(props) => props.$theme.dark};
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: ${(props) => props.$theme.main};
    height: 2rem;
    width: 1rem;
  }

  input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid ${(props) => props.$theme.dark};
    outline: 3px solid ${(props) => props.$theme.dark};
    outline-offset: 0.125rem;
  }
`;
const GlobalVolumeSliderInput = styled.input``;

const StyledVolumeDown = styled(FontAwesomeIcon)`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  padding: 2px;
  font-size: 12px;
  margin-right: 4px;
`;
const StyledVolumeUp = styled(FontAwesomeIcon)`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 2px;
  font-size: 16px;
  margin-left: 4px;
`;

export default GlobalVolumeSlider;
