import { useAuth } from "context/Auth";

import {
  AvatarCreator,
  AvatarCreatorConfig,
  AvatarExportedEvent,
} from "@readyplayerme/react-avatar-creator";
import { useState } from "react";

const config: AvatarCreatorConfig = {
  clearCache: false,
  bodyType: "fullbody",
  quickStart: false,
  language: "en",
};

const style = { width: "100%", height: "100vh", border: "none" };

function ReadyPlayerMeFrame() {
  const [showLoader, setShowLoader] = useState(false);
  const { createNewCharacter } = useAuth();

  const handleOnAvatarExported = (event: AvatarExportedEvent) => {
    setShowLoader(true);
    createNewCharacter(event.data.url);
  };

  return (
    <>
      {showLoader && <div>Loading...</div>}
      <AvatarCreator
        subdomain="micro-realms"
        config={config}
        style={style}
        onAvatarExported={handleOnAvatarExported}
      />
    </>
  );
}

export default ReadyPlayerMeFrame;
