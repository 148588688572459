import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
const loadingStrings = [
  "Alphabetizing Books",
  "Burning Calories",
  "Caffeinating Developers",
  "Decrypting Enigma",
  "Embiggening Prototypes",
  "Finding someone to refill the water cooler",
  "Generating witty dialog",
  "Hiding all the Easter eggs",
  "Initializing the initializer",
  "Jumping to hyperspace",
  "Keeping the packets flowing",
  "Looking for exact change",
  "Making sure all the i's have dots",
  "Negotiating with the elves at the Keebler factory",
  "Ordering 1s and 0s",
  "Placing princesses into different towers",
  "Querying quantum bits",
  "Realigning the gravity",
  "Shovelling coal into the server",
  "Trying to sort in O(n) time",
  "Unpacking the data packet",
  "Venting oxygen tanks",
  "Waiting for the system admin to hit enter",
  "X-raying a mirror",
  "Yodeling at the kernel",
  "Zeroing out the zeros",
];

const CircleProgressLoader: React.FC<{
  loadingString?: string;
  useMessages?: boolean;
}> = ({ loadingString, useMessages }) => {
  const [loadingText, setLoadingText] = useState<string>(
    useMessages ? loadingStrings[0] : loadingString ?? "Loading..."
  );

  useEffect(() => {
    if (loadingStrings) {
      let prevIndex = 0,
        randomIndex = 0;
      const interval = setInterval(() => {
        prevIndex = randomIndex;
        // pick a random index that is not the same as the previous one
        while (prevIndex === randomIndex) {
          randomIndex = Math.floor(Math.random() * loadingStrings.length);
        }

        setLoadingText(loadingStrings[randomIndex]);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner
        style={{ width: "80px", height: "80px" }}
        animation="border"
        role="status"
      ></Spinner>
      <span style={{ marginTop: "32px" }}>{loadingText ?? "Loading..."}</span>
    </div>
  );
};

export default CircleProgressLoader;
