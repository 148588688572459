import styled from "styled-components";

const GeneralStyles = {
  Root: {
    FullWidth: styled.div`
      width: 100%;
      margin: 0 auto;
      padding: 0 auto;
    `,
    Default: styled.div`
      width: 70%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 auto;

      /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: 393px) {
        max-width: 373px;
      }

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 393px) {
        max-width: 373px;
      }

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        max-width: 393px;
      }

      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        max-width: 500px;
      }

      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        max-width: 700px;
      }

      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1200px) {
        max-width: 900px;
      }
    `,
  },
};

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

export default GeneralStyles;
