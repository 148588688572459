import {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  AnonPlayfabLogin,
  GetAllUsersCharacters,
  GetCharacterData,
  GrantCharacterToUser,
} from "actions/playfab";

interface PlayerData {
  _id: string;
  avatarModelURL: string;
  username: string;
  character_id: string;
  playfab_id: string;
  hasBeenSet?: boolean;
}

const defaultLoginData: PlayerData = {
  _id: "",
  avatarModelURL: "",
  username: "",
  character_id: "",
  playfab_id: "",
  hasBeenSet: false,
};

export const AuthContext = createContext<any>({});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const TITLE_ID = process.env.REACT_APP_TITLE_ID || "";
  // const SECRET = process.env.REACT_APP_SECRET || "";
  const navigate = useNavigate();

  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAnonLoggedIn, setIsAnonLoggedIn] = useState(false);

  const [playerUsername, setPlayerUsername] = useState("");

  const [playerData, setPlayerData] = useState<PlayerData>(defaultLoginData);

  const [authErrorMessage, setAuthErrorMessage] = useState("");

  const handleSetPlayerData = (
    _playerData: React.SetStateAction<PlayerData>
  ) => {
    console.log("setting player data", _playerData);
    localStorage.setItem("playerData", JSON.stringify(_playerData));
    setPlayerData(_playerData);
  };

  // const updatePlayerData = (property: keyof PlayerData, value: any) => {
  //   setPlayerData((prevData) => {
  //     const updatedData = {
  //       ...prevData,
  //       [property]: value,
  //     };
  //     localStorage.setItem("playerData", JSON.stringify(updatedData));
  //     return updatedData;
  //   });
  // };

  // const handleSetAuthError = (authErrorMessage: AuthErrorEnum) => {
  //   switch (authErrorMessage) {
  //     case AuthErrorEnum.UsernameExists:
  //       setAuthErrorMessage(
  //         "That username is taken, please choose a different one"
  //       );
  //   }
  //   setTimeout(() => {
  //     setAuthErrorMessage("");
  //   }, 5000);
  // };

  // const hasDateExpired = (_givenDate: string) => {
  //   const givenDate = new Date(_givenDate);
  //   const currentDate = new Date();

  //   if (currentDate > givenDate) {
  //     console.log("The given date has expired");
  //     return true;
  //   } else {
  //     console.log("The given date has not expired");
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   const localPlayerData = localStorage.getItem("playerData");
  //   const localPlayerCustomID = localStorage.getItem("playerCustomID");

  //   if (
  //     localPlayerData &&
  //     localPlayerCustomID &&
  //     isPingingAPI.current === false
  //   ) {
  //     setPlayerUsername(localPlayerCustomID.split("@")[0]);
  //     isPingingAPI.current = true;
  //     const parsedPlayerData: PlayFabClientModels.LoginResult =
  //       JSON.parse(localPlayerData);
  //     const tokenToValidate = parsedPlayerData.EntityToken;
  //     console.log("Player Login Data", parsedPlayerData);
  //     console.log("Token to validate", tokenToValidate);

  //     if (
  //       tokenToValidate?.EntityToken === undefined ||
  //       tokenToValidate.TokenExpiration === undefined ||
  //       localPlayerCustomID === ""
  //     ) {
  //       console.error("no token");
  //       return;
  //     }
  //     anonymousLogin(localPlayerCustomID);
  //   } else {
  //     console.log("NO DATA");
  //     setIsAuthLoading(false);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  // call this function when you want to authenticate the user

  const handleAnonLogin = useCallback(
    (username: string) => {
      setIsAuthLoading(true);
      setPlayerUsername(username);
      AnonPlayfabLogin(username).then((res) => {
        GetAllUsersCharacters().then((charsRes) => {
          // no characters exist on player
          if (
            !charsRes.data.Characters ||
            charsRes.data.Characters?.length === 0
          ) {
            console.log("No characters detected, creating new character");
            navigate("/avatar");
            return;
          }

          // at least one character exist on player
          const firstChar = charsRes.data.Characters[0];
          GetCharacterData(firstChar?.CharacterId!).then((charDataRes) => {
            console.log("Character(s) found", charDataRes);

            const charData = charDataRes.data.Data!;
            const avatarModelURL = charData.avatarModelURL.Value;
            localStorage.setItem(
              "playerData",
              JSON.stringify({ username, avatarModelURL })
            );
            setPlayerData((prev: any) => {
              return { ...prev, username, avatarModelURL };
            });
            setIsAuthorized(true);
            setIsAuthLoading(false);
            setIsAnonLoggedIn(true);

            const redirectURL = localStorage.getItem("returnPath");
            if (redirectURL) {
              navigate(redirectURL);
              localStorage.removeItem("returnPath");
              return;
            }
            navigate("/home");
          });
        });
      });
    },
    [navigate]
  );

  const handleAnonRegistration = useCallback((username: string) => {
    // setIsAuthLoading(true);
    localStorage.setItem("username", username);
    setPlayerUsername(username);
  }, []);

  const updateAvatarURL = useCallback(
    (avatarModelURL: string) => {
      console.log("update with this", avatarModelURL, playerData);
      if (!playerData) return;
      setPlayerData((prev: any) => {
        localStorage.setItem(
          "playerData",
          JSON.stringify({ ...prev, avatarModelURL })
        );
        return { ...prev, avatarModelURL };
      });
      // apiActions
      //   .SaveAvatarURL(
      //     avatarModelURL,
      //     playerData._id,
      //     playerData.username
      //   )
      //   .then((res) => {
      //     setPlayerData((prev: any) => {
      //       localStorage.setItem(
      //         "playerData",
      //         JSON.stringify({ ...prev, avatarModelURL })
      //       );
      //       return { ...prev, avatarModelURL };
      //     });
      //     console.log("Setting Avatar URL", res);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    },
    [playerData]
  );

  const handlLocalAnonLogin = () => {
    const localUserData = localStorage.getItem("playerData")!;
    const parsedUserData = JSON.parse(localUserData);
    setPlayerData(parsedUserData);
    setIsAuthorized(true);
    navigate("/home");
  };

  // call this function to sign out logged in user
  const logout = () => {
    localStorage.setItem("playerData", "");
    setPlayerData(defaultLoginData);
    setIsAuthorized(false);
    navigate("/");
  };

  const createNewCharacter = (avatarURL: string) => {
    GrantCharacterToUser(playerUsername, avatarURL)
      .then((res) => {
        console.log("Created new character", res);
        setPlayerData((prev) => {
          localStorage.setItem(
            "playerData",
            JSON.stringify({
              ...prev,
              username: playerUsername,
              character_id: res.character.data.CharacterId!,
              avatarModelURL: avatarURL,
            })
          );
          return {
            ...prev,
            username: playerUsername,
            character_id: res.character.data.CharacterId!,
            avatarModelURL: avatarURL,
          };
        });
        setTimeout(() => {
          navigate("/home");
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const localUserData = localStorage.getItem("playerData")!;
    console.log("localUserData", localUserData);
    if (!localUserData || localUserData === "undefined") {
      console.log("no data");
      setIsAuthLoading(false);
      return;
    }

    const parsedUserData = JSON.parse(localUserData);
    console.log("has local data", parsedUserData);
    if (!parsedUserData.username) {
      setIsAuthLoading(false);
      return;
    }

    setPlayerData((prev) => ({
      ...prev,
      avatarModelURL: parsedUserData.avatarModelURL,
      username: parsedUserData.username,
    }));
    setIsAuthorized(true);
    setIsAuthLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleAnonRegistration,
        handleAnonLogin,
        logout,
        isAuthorized,
        setIsAuthorized,
        isAnonLoggedIn,
        setIsAnonLoggedIn,
        authErrorMessage,
        setAuthErrorMessage,
        playerData,
        setPlayerData: handleSetPlayerData,
        isAuthLoading,
        setIsAuthLoading,
        playerUsername,
        handlLocalAnonLogin,
        updateAvatarURL,
        createNewCharacter,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface AuthContextType {
  handleAnonRegistration: (username: string) => void;
  handleAnonLogin: (username: string) => void;
  logout: () => void;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  isAnonLoggedIn: boolean;
  setIsAnonLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  authErrorMessage: string;
  setAuthErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  playerData: PlayerData | null;
  setPlayerData: React.Dispatch<React.SetStateAction<PlayerData>>;
  isAuthLoading: boolean;
  setIsAuthLoading: React.Dispatch<React.SetStateAction<boolean>>;
  playerUsername: string;
  handlLocalAnonLogin: () => void;
  updateAvatarURL: (avatarModelURL: string) => void;
  createNewCharacter: (avatarURL: string) => void;
}

export const useAuth = () => {
  return useContext(AuthContext) as AuthContextType;
};

export default AuthProvider;
