import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "context/Auth";

import LandingPageStyles from "./LandingPage.styles";

import HeroImage from "assets/images/Avatars/groups/avatars-group-2.png";
import HeroMobileImage from "assets/images/Avatars/groups/avatars-group-3.png";
import PoweredByImage from "assets/images/poweredby/light-dark-bg.png";
import { useLessthanBreakpoint } from "hooks";

const LandingPage = () => {
  const {
    // handleAnonRegistration,
    handleAnonLogin,
    authErrorMessage,
    isAuthLoading,
  } = useAuth();
  const [username, setUsername] = useState("");
  const [hasLocalData, setHasLocalData] = useState(false);
  const isMediumScreen = useLessthanBreakpoint("md");
  const navigate = useNavigate();

  useEffect(() => {
    const localUserData = localStorage.getItem("playerData");
    if (!localUserData || localUserData === "undefined") return;

    const parsedUserData = JSON.parse(localUserData);
    if (!parsedUserData.username) return;

    setUsername(parsedUserData.username);
    navigate("/home");
    // eslint-disable-next-line
  }, []);

  const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUsername(value);
  };

  const handleRegister = () => {
    console.log("handleRegister", handleAnonLogin);
    handleAnonLogin(username);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleAnonLogin(username);
  };

  return (
    <LandingPageStyles.Root>
      {/* Left side of landing page */}
      <LandingPageStyles.HeroContainer>
        <LandingPageStyles.TextContainer>
          <LandingPageStyles.TitleText>
            &#x03BC;Realms
          </LandingPageStyles.TitleText>
          <LandingPageStyles.ExplainationText>
            Micro or &#x03BC; is a unit prefix that comes from the Greek word
            mikrós, meaning "small".
          </LandingPageStyles.ExplainationText>
          <LandingPageStyles.SubtitleText>
            Micro metaverse realms you can join and share with everyone
          </LandingPageStyles.SubtitleText>
        </LandingPageStyles.TextContainer>
        <LandingPageStyles.HeroImageContainer>
          {isMediumScreen ? (
            <LandingPageStyles.HeroMobileImage
              src={HeroMobileImage}
              alt="A group of 4 vitual avatars"
            />
          ) : (
            <LandingPageStyles.HeroImage
              src={HeroImage}
              alt="A group of 4 vitual avatars"
            />
          )}
        </LandingPageStyles.HeroImageContainer>
        <div>
          <LandingPageStyles.PoweredByImage src={PoweredByImage} />
        </div>
      </LandingPageStyles.HeroContainer>

      {/* Overlay for Login Container */}
      {isAuthLoading && (
        <LandingPageStyles.LoadingOverlay>
          <Spinner
            style={{ width: "80px", height: "80px" }}
            animation="border"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </LandingPageStyles.LoadingOverlay>
      )}
      {/* Right side of landing page */}
      <LandingPageStyles.FormContainer>
        <div>
          <div style={{ textAlign: "center", marginBottom: "12px" }}>
            Click this button to try out Trivia Time!
          </div>
          <Button onClick={() => navigate("/trivia")} style={{ width: "100%" }}>
            Go To Trivia
          </Button>
        </div>
        <LandingPageStyles.InputContainer>
          <LandingPageStyles.FormTitle>
            {hasLocalData ? "Welcome back!" : "Enter username"}
          </LandingPageStyles.FormTitle>
          <LandingPageStyles.FormSubtitle>
            {hasLocalData
              ? "Click the button below to rejoin a \u03BCRealm"
              : "Join a realm by just entering your username"}
          </LandingPageStyles.FormSubtitle>

          {hasLocalData ? (
            <LandingPageStyles.FormLocalUserExists>
              <span>{username}</span>
              <LandingPageStyles.EditLocalUsername
                onClick={() => setHasLocalData(false)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </LandingPageStyles.EditLocalUsername>
            </LandingPageStyles.FormLocalUserExists>
          ) : (
            <form onSubmit={handleSubmit}>
              <LandingPageStyles.FormUsernameInput
                id="inputUsername"
                placeholder="Enter a username"
                value={username}
                onChange={handleUsername}
              />
              <LandingPageStyles.FormErrorMessage>
                {authErrorMessage}
              </LandingPageStyles.FormErrorMessage>
            </form>
          )}
          <LandingPageStyles.FormSubmitButton onClick={handleRegister}>
            Enter a &#x03BC;Realm
          </LandingPageStyles.FormSubmitButton>
        </LandingPageStyles.InputContainer>
        <LandingPageStyles.FormFooter>
          <div>Already have an account?</div>
          <NavLink to={"/login"} replace>
            Click here
          </NavLink>
        </LandingPageStyles.FormFooter>
      </LandingPageStyles.FormContainer>
    </LandingPageStyles.Root>
  );
};

export default LandingPage;
