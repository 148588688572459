import { useAuth } from "context/Auth";
import Button from "common/Button";
import HeaderIconImage from "assets/images/logos/micro-logo-white.png";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import GlobalVolumeSlider from "./GlobalVolumeSlider";

interface Props {
  handleShowLogout: () => void;
}

const DesktopLayout = ({ handleShowLogout }: Props) => {
  const { isAuthorized } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <LayoutToolbar id="toolbar">
      <div id="leftside">
        <img src={HeaderIconImage} height="28" alt="" />
      </div>
      <div
        id="rightside"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {currentPath === "/trivia" && <GlobalVolumeSlider />}
        {currentPath !== "/trivia" && (
          <StyledButton onClick={() => navigate("/trivia")}>
            Trivia Time
          </StyledButton>
        )}
        {currentPath !== "/home" && (
          <StyledButton
            color={currentPath === "/trivia" ? "trivia-primary" : "primary"}
            onClick={() => navigate("/home")}
          >
            Home
          </StyledButton>
        )}
        {isAuthorized && (
          <StyledButton
            color={currentPath === "/trivia" ? "trivia-primary" : "primary"}
            onClick={handleShowLogout}
          >
            Logout
          </StyledButton>
        )}
      </div>
    </LayoutToolbar>
  );
};
const LayoutToolbar = styled.div`
  background-color: #0e0e0e99;
  backdrop-filter: blur(2px);
  padding: 8px 32px;
  position: absolute;
  z-index: 9999;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledButton = styled(Button)`
  margin-right: 12px;
`;
export default DesktopLayout;
