import { useEffect, useState } from "react";
import Question from "./components/Question";
import Answers from "./components/Answers";
import { TriviaQuestion } from "actions/types";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import Countdown from "./components/Countdown";
import { PowerupProps, PowerupTypes } from "./TriviaMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  question: TriviaQuestion;
  answers: string[];
  selectedAnswer: string;
  setSelectedAnswer: (answer: string) => void;
  handleSubmitAnswer: () => void;
  playBongSound: () => void;
  startMusic: () => void;
  stopMusic: () => void;
  currentPowerUp: PowerupProps | null;
  activatePowerup: () => void;
  skipKey: string;
  powerupState: "unselected" | "available" | "activated" | "used";
};

const QuestionStep = ({
  question,
  answers,
  selectedAnswer,
  setSelectedAnswer,
  handleSubmitAnswer,
  playBongSound,
  startMusic,
  stopMusic,
  currentPowerUp,
  activatePowerup,
  skipKey,
  powerupState,
}: Props) => {
  const [cancelTimer, setCancelTimer] = useState(false);
  const [fiftyFiftyActivated, setFiftyFiftyActivated] = useState(false);

  const fiftyFiftyAnswers = (): string[] => {
    let incorrectAnswerCount = 2;
    return answers.filter((answer) => {
      if (
        answer !== question.correct_answer &&
        answer !== question.correct_answer + "***" &&
        incorrectAnswerCount > 0
      ) {
        incorrectAnswerCount -= 1;
        return null;
      } else {
        return answer;
      }
    });
  };

  const onPressAnswerSubmit = () => {
    setCancelTimer(true);
    handleSubmitAnswer();
  };

  const onSelectAnswer = (answer: string) => {
    playBongSound();
    if (answer.endsWith("***")) {
      setSelectedAnswer(answer.slice(0, -3));
    } else {
      setSelectedAnswer(answer);
    }
  };

  useEffect(() => {
    startMusic();
    return () => {
      stopMusic();
    };
  }, [startMusic, stopMusic]);

  const showPowerup = () => {
    return (
      (currentPowerUp?.key === PowerupTypes.SKIP_QUESTION ||
        currentPowerUp?.key === PowerupTypes.FIFTY_FIFTY) &&
      powerupState === "available"
    );
  };

  const onUsePowerup = () => {
    if (currentPowerUp?.key === PowerupTypes.SKIP_QUESTION) {
      activatePowerup();
      onSelectAnswer(skipKey);
      onPressAnswerSubmit();
    }
    if (currentPowerUp?.key === PowerupTypes.FIFTY_FIFTY) {
      setFiftyFiftyActivated(true);
      activatePowerup();
    }
  };

  return (
    <TriviaPageRoot>
      <TriviaCategory>{question.category}</TriviaCategory>
      <Question question={question.question} />
      <Countdown
        onTimerExpired={handleSubmitAnswer}
        timerCancelled={cancelTimer}
        timerSeconds={12}
      />
      <Answers
        answers={fiftyFiftyActivated ? fiftyFiftyAnswers() : answers}
        selectedAnswer={selectedAnswer}
        setSelectedAnswer={onSelectAnswer}
      />

      <ButtonContainer>
        <SubmitButton
          disabled={!selectedAnswer}
          variant="dark"
          onClick={onPressAnswerSubmit}
        >
          {selectedAnswer ? "Submit" : "Select an Answer"}
        </SubmitButton>
        {currentPowerUp && showPowerup() && (
          <IconButton
            variant="dark"
            disabled={fiftyFiftyActivated}
            onClick={onUsePowerup}
          >
            <FontAwesomeIcon icon={currentPowerUp.icon} />
          </IconButton>
        )}
      </ButtonContainer>
    </TriviaPageRoot>
  );
};

const TriviaPageRoot = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease-in-out;
  @media (max-width: 768px) {
    margin-top: -32px;
  }
`;

const TriviaCategory = styled.div`
  font-size: 20px;
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-size: 20px;
`;
const IconButton = styled(Button)`
  padding: 8px 16px;
  margin-left: 12px;
`;

const SubmitButton = styled(Button)`
  padding: 8px 48px;
`;

export default QuestionStep;
