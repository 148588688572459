import React, { useContext, createContext, useState, useRef } from "react";
import useSound from "use-sound";

import OneSFX from "assets/audio/1.ogg";
import TwoSFX from "assets/audio/2.ogg";
import ThreeSFX from "assets/audio/3.ogg";
import BackSFX from "assets/audio/back.ogg";
import BongSFX from "assets/audio/bong.ogg";
import ConfirmationSFX from "assets/audio/confirmation.ogg";
import CongratulationsSFX from "assets/audio/congratulations.ogg";
import CorrectSFX from "assets/audio/correct.mp3";
import GameOverSFX from "assets/audio/game_over.ogg";
import HurryUpSFX from "assets/audio/hurry_up.ogg";
import TimeOverSFX from "assets/audio/time_over.ogg";
import WrongSFX from "assets/audio/wrong.mp3";
import YouLoseSFX from "assets/audio/you_lose.ogg";
import BackgroundMusicSFX from "assets/audio/bg_music.mp3";

export const AudioContext = createContext<any>({});

interface Props {
  children: React.ReactNode;
}

// create a mapped object of audio files in the assets/audio folder
const audioFiles: { [key: string]: string } = {
  one: OneSFX,
  two: TwoSFX,
  three: ThreeSFX,
  back: BackSFX,
  bong: BongSFX,
  confirmation: ConfirmationSFX,
  congratulations: CongratulationsSFX,
  correct: CorrectSFX,
  wrong: WrongSFX,
  gameOver: GameOverSFX,
  hurryUp: HurryUpSFX,
  timeOver: TimeOverSFX,
  youLose: YouLoseSFX,
  backgroundMusic: BackgroundMusicSFX,
};

type AudioKeys = keyof typeof audioFiles;

const AudioProvider: React.FC<Props> = ({ children }) => {
  const storedVolume = localStorage.getItem("globalVolume");

  const [globalVolume, setGlobalVolume] = useState(
    storedVolume ? parseInt(storedVolume) : 50
  );
  const lastVolume = useRef(storedVolume ? parseInt(storedVolume) : 50);
  const [globalMuted, setGlobalMuted] = useState(false);

  const setVolume = (volume: number) => {
    setGlobalMuted(false);
    setGlobalVolume(volume);
    lastVolume.current = volume;
    localStorage.setItem("globalVolume", volume.toString());
  };

  const toggleMute = (muteState: boolean) => {
    setGlobalMuted(muteState);
    if (muteState) {
      lastVolume.current = globalVolume;
      setGlobalVolume(0);
    } else {
      setGlobalVolume(lastVolume.current);
    }
  };

  const useSingleSound = (soundKey: AudioKeys) =>
    useSound(audioFiles[soundKey], { volume: globalVolume / 100 });

  return (
    <AudioContext.Provider
      value={{
        volume: globalVolume,
        setVolume,
        mute: globalMuted,
        toggleMute,
        audioFiles,
        useSingleSound,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  return useContext(AudioContext) as any;
};
export default AudioProvider;
