import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import styled from "styled-components";

interface Props {
  selectedDifficulty: string;
  selectDifficulty: (difficulty: "easy" | "medium" | "hard" | "") => void;
  getQuestionPointValue: (
    difficulty: "easy" | "medium" | "hard" | ""
  ) => number;
  hasActivatedDoublePoints: boolean;
}

const DifficultySelection = ({
  selectedDifficulty,
  selectDifficulty,
  getQuestionPointValue,
  hasActivatedDoublePoints,
}: Props) => {
  const pointsMultiplier = hasActivatedDoublePoints ? 2 : 1;
  const renderTooltip = (props: OverlayInjectedProps, pointText: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {pointText}
    </Tooltip>
  );

  return (
    <>
      <DifficultyText>Select a difficulty level</DifficultyText>
      <DifficultyButtonGroup>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 10, hide: 200 }}
          overlay={(props) =>
            renderTooltip(
              props,
              `${getQuestionPointValue("easy") * pointsMultiplier} points`
            )
          }
        >
          <DifficultyButton
            variant="light"
            disabled={selectedDifficulty === "easy"}
            onClick={() => selectDifficulty("easy")}
          >
            Easy
          </DifficultyButton>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) =>
            renderTooltip(
              props,
              `${getQuestionPointValue("medium") * pointsMultiplier} points`
            )
          }
        >
          <DifficultyButton
            variant="light"
            disabled={selectedDifficulty === "medium"}
            onClick={() => selectDifficulty("medium")}
          >
            Medium
          </DifficultyButton>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) =>
            renderTooltip(
              props,
              `${getQuestionPointValue("hard") * pointsMultiplier} points`
            )
          }
        >
          <DifficultyButton
            variant="light"
            disabled={selectedDifficulty === "hard"}
            onClick={() => selectDifficulty("hard")}
          >
            Hard
          </DifficultyButton>
        </OverlayTrigger>
      </DifficultyButtonGroup>
    </>
  );
};

const DifficultyText = styled.div`
  font-family: "Tilt Neon";
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const DifficultyButtonGroup = styled(ButtonGroup)`
  margin-bottom: 32px;
  width: 100%;
`;

const DifficultyButton = styled(Button)`
  font-family: "Tilt Neon";
  min-width: 90px;
  &:disabled {
    font-weight: bold;
  }
`;

export default DifficultySelection;
