import actions from "actions";
import { TriviaQuestion } from "actions/types";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import QuestionStep from "./QuestionStep";
import Intro from "./Intro";
import CategorySelect from "./CategorySelect";
import ResultStep from "./ResultStep";
import { useAudio } from "context/AudioContext/AudioContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faFire,
  faHeart,
  faHeartBroken,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle,
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";
import GameOver from "./components/GameOver";
import CircleProgressLoader from "components/CircleProgressLoader";
import IconButton from "common/IconButton/IconButton";
import AuthStep from "./AuthStep";
import PowerupStep from "./PowerupStep";
import {
  faAnglesUp,
  faHandHoldingHeart,
  faFireBurner,
  faForward,
  faCircleHalfStroke,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
export interface TriviaCategoryProps {
  id: number;
  name: string;
}
// const defaultTriviaCategory: TriviaCategoryProps = {
//   id: 0,
//   name: "",
// };

export enum TriviaSteps {
  "INTRO",
  "AUTH",
  "POWERUP",
  "SELECT_CATEGORY",
  "SELECT_DIFFICULTY",
  "ANSWER_QUESTION",
  "ANSWER_RESULTS",
  "GAME_OVER",
  "GAME_WIN",
}

export enum PowerupTypes {
  "DOUBLE_POINTS", // Before a difficulty is selected, the user can choose to double the points earned from the next question
  "LIFE_SAVER", // when the user gets a question wrong, they can choose to use a life to continue the game
  "STREAK_SAVER", // when the user gets a question wrong, they can continue their streak by using a streak bonus
  "SKIP_QUESTION", // the user can skip the current question and move on to the next one (counts as a correct answer, without points)
  "FIFTY_FIFTY", // the user can eliminate two incorrect answer choices from the current question
}

// TODO: Integrate powerups in areas that need the functionality to trigger
// ? DOUBLE_POINTS
// show in category select
// pass currentPowerup and handlePowerup function down
// create powerupUsed boolean
// when administering points, check if currentPowerup is DOUBLE_POINTS and if so, double the points awarded

// ? LIFE_SAVER
// show in answer results
// pass currentPowerup and handlePowerup function down
// when subtracting life, check if currentPowerup is LIFE_SAVER and if so, don't subtract life

// ? STREAK_SAVER
// show in answer results
// pass currentPowerup and handlePowerup function down
// when subtracting life, check if currentPowerup is STREAK_SAVER and if so, don't reset streak

// ? SKIP_QUESTION
// show in question step
// pass currentPowerup
// in answer buttons, show skip button if currentPowerup is SKIP_QUESTION

// ? FIFTY_FIFTY
// show in question step
// pass currentPowerup
// in answer buttons, show 50/50 button if currentPowerup is FIFTY_FIFTY
// filter out two incorrect answers from answers array

export interface PowerupProps {
  key: PowerupTypes;
  icon: IconProp;
  title: string;
  description: string;
}

// const defaultQuestion: TriviaQuestion = {
//   category: "Entertainment: Cartoon & Animations",
//   type: "multiple",
//   difficulty: "medium",
//   question:
//     "The stop motion comedy show &quot;Robot Chicken&quot; was created by which of the following?",

//   correct_answer: "Seth Green",
//   incorrect_answers: ["Seth MacFarlane", "Seth Rogen", "Seth Rollins"],
// };
export const powerupMap = {
  DOUBLE_POINTS: {
    key: PowerupTypes.DOUBLE_POINTS,
    icon: faAnglesUp,
    title: "Double Points",
    description: "Doubles the points earned from the next question",
  },
  LIFE_SAVER: {
    key: PowerupTypes.LIFE_SAVER,
    icon: faHandHoldingHeart,
    title: "Life Saver",
    description: "Grants an extra life to use in case of a wrong answer",
  },
  STREAK_SAVER: {
    key: PowerupTypes.STREAK_SAVER,
    icon: faFireBurner,
    title: "Streak Saver",
    description:
      "Grants bonus points for answering multiple questions correctly in a row",
  },
  SKIP_QUESTION: {
    key: PowerupTypes.SKIP_QUESTION,
    icon: faForward,
    title: "Skip Question",
    description:
      "Allows the user to skip the current question and move on to the next one",
  },
  FIFTY_FIFTY: {
    key: PowerupTypes.FIFTY_FIFTY,
    icon: faCircleHalfStroke,
    title: "50/50",
    description:
      "Eliminates two incorrect answer choices from the current question",
  },
};
const TriviaMain = () => {
  const IS_DEV = process.env.NODE_ENV === "development";
  const SKIP_KEY = "SKIP_TRIVIA_QUESTION";
  const MAX_QUESTIONS = 10;
  const BASE_POINTS = 10;
  const MAX_LIVES = 3;
  const MAX_CATEGORIES = 4;
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<TriviaSteps>(
    TriviaSteps.INTRO
  );
  const { fetchTriviaQuestions, fetchTriviaCategories } = actions.trivia;
  const [categories, setCategories] = useState<TriviaCategoryProps[]>([]);
  const [currentCategory, setCurrentCategory] =
    useState<TriviaCategoryProps | null>(null);
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard" | "">(
    ""
  );
  const [currentQuestion, setCurrentQuestion] =
    useState<TriviaQuestion | null>();
  const [answers, setAnswers] = useState<string[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [answerHistory, setAnswerHistory] = useState<
    {
      question: TriviaQuestion;
      answer: string;
      pointsAwarded: number;
      correct: boolean;
      powerupUsed?: string;
    }[]
  >([]);
  const [score, setScore] = useState<number>(0);
  const [lives, setLives] = useState<number>(MAX_LIVES);
  const [streak, setStreak] = useState<number>(0);
  const [currentPowerup, setCurrentPowerup] = useState<PowerupProps | null>(
    null
  );
  const [powerupSelected, setPowerupSelected] = useState(true);
  const [continueAnyway, setContinueAnyway] = useState(false);
  const [powerupState, setPowerupState] = useState<
    "unselected" | "available" | "activated" | "used"
  >("unselected");

  const { useSingleSound } = useAudio();

  const [playConfirmSound] = useSingleSound("confirmation");
  const [playBongSound] = useSingleSound("bong");
  const [playCorrectSound] = useSingleSound("correct");
  const [playIncorrectSound] = useSingleSound("wrong");
  const [playBGMusic, { stop: stopBGMusic }] =
    useSingleSound("backgroundMusic");

  useEffect(() => {
    const triviaResetDate = localStorage.getItem("triviaResetDate");
    if (triviaResetDate) {
      const resetDate = new Date(triviaResetDate);
      const currentDate = new Date();
      if (currentDate < resetDate) {
        setCurrentStep(TriviaSteps.GAME_OVER);
        hideLoader();

        return;
      } else {
        localStorage.removeItem("triviaResetDate");
        localStorage.removeItem("triviaStats");
      }
    }
    getCategories();
    hideLoader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hideLoader = () => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  };

  const goToStep = (step: TriviaSteps) => {
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    playConfirmSound();
    setCurrentStep((currentStep) => {
      if (currentStep === TriviaSteps.INTRO) {
        if (continueAnyway && !powerupSelected) {
          return TriviaSteps.POWERUP;
        }
        if (continueAnyway && powerupSelected) {
          return TriviaSteps.SELECT_CATEGORY;
        }
      }
      if (currentStep === TriviaSteps.AUTH) {
        setContinueAnyway(true);
      }
      if (currentStep === TriviaSteps.POWERUP) {
        if (powerupSelected) {
          return TriviaSteps.SELECT_CATEGORY;
        }
        return currentStep;
      }
      return currentStep + 1;
    });
  };

  const handlePrevStep = () => {
    playBongSound();
    setCurrentStep((currentStep) => {
      if (currentStep - 1 < 0) return 0;
      if (currentStep === TriviaSteps.POWERUP && continueAnyway) {
        return TriviaSteps.INTRO;
      }
      if (currentStep === TriviaSteps.SELECT_CATEGORY && currentCategory) {
        setCurrentCategory(null);
        return currentStep;
      }
      if (powerupSelected) {
        return TriviaSteps.INTRO;
      }
      return currentStep - 1;
    });
  };

  const getCategories = (cb?: () => void) => {
    fetchTriviaCategories()
      .then((fetchedCategories) => {
        const getRandomIndices = (max: number, length: number): number[] => {
          const indices = new Set<number>();
          while (indices.size < length) {
            indices.add(Math.floor(Math.random() * max));
          }
          return Array.from(indices);
        };

        const categoryIndecies = getRandomIndices(
          fetchedCategories.length,
          MAX_CATEGORIES
        );

        setCategories(() => {
          const filteredCategories = fetchedCategories.filter(
            (_: TriviaCategoryProps, i: number) => categoryIndecies.includes(i)
          );
          if (cb) {
            cb();
          }
          return filteredCategories;
        });
      })
      .catch((error) => {
        console.error("Error fetching trivia categories:", error);
      });
  };

  const shuffleArray = (array: any[]) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const getQuestion = () => {
    fetchTriviaQuestions(1, {
      category: currentCategory?.id,
      difficulty,
    })
      .then((fetchedQuestions) => {
        if (fetchedQuestions) {
          setCurrentQuestion(fetchedQuestions[0]);
          setAnswers(
            shuffleArray([
              ...fetchedQuestions[0].incorrect_answers,
              IS_DEV
                ? fetchedQuestions[0].correct_answer + "***"
                : fetchedQuestions[0].correct_answer,
            ])
          );
          playConfirmSound();
        }
      })
      .catch((error) => {
        console.error("Error fetching trivia questions:", error);
      });
  };

  const handleSubmitAnswer = () => {
    hideLoader();
    setCurrentStep(TriviaSteps.ANSWER_RESULTS);
  };

  const resetQuiz = () => {
    if (powerupState === "activated") {
      setPowerupState("used");
    }

    if (answerHistory.length >= MAX_QUESTIONS || lives <= 0) {
      const currentDate = new Date();
      const resetDate = new Date();
      resetDate.setDate(currentDate.getDate() + 1);
      resetDate.setHours(0, 0, 0, 0);
      localStorage.setItem("triviaResetDate", resetDate.toISOString());
      setCurrentStep(TriviaSteps.GAME_OVER);
      storeTriviaStats();
      hideLoader();
      return;
    }

    setCurrentQuestion(null);
    setSelectedAnswer("");
    setDifficulty("");
    setCurrentCategory(null);
    if (powerupState === "activated") {
      setPowerupState("used");
    }

    getCategories(() => {
      setCurrentStep(TriviaSteps.SELECT_CATEGORY);
      hideLoader();
    });
  };

  const addToAnswerHistory = (
    answer: string,
    correct: boolean,
    powerup?: string
  ) => {
    if (!currentQuestion) return;
    setAnswerHistory((prevHistory) => [
      ...prevHistory,
      {
        question: currentQuestion,
        pointsAwarded: !correct ? 0 : getQuestionPointValue(difficulty),
        answer,
        correct,
        powerupUsed: powerup,
      },
    ]);
  };

  const addToScore = () => {
    const pointNultiplier = getQuestionPointValue(difficulty);
    setScore((prevPoints) => prevPoints + pointNultiplier);
    if (currentPowerup?.key === PowerupTypes.DOUBLE_POINTS) {
      setPowerupState("used");
    }
  };

  const subtractLife = () => {
    setLives((prevLives) => {
      return prevLives - 1;
    });
  };

  const addStreak = () => {
    setStreak((prevStreak) => prevStreak + 1);
  };

  const resetStreak = () => {
    if (
      currentPowerup?.key === PowerupTypes.STREAK_SAVER &&
      powerupState === "activated"
    ) {
      setPowerupState("used");
      return;
    }
    setStreak(0);
  };

  const storeTriviaStats = () => {
    const triviaStats = {
      score,
      lives,
      streak,
      answerHistory,
    };
    localStorage.setItem("triviaStats", JSON.stringify(triviaStats));
  };

  const renderLives = () => {
    const livesArray = [];
    for (let i = MAX_LIVES; i > 0; i--) {
      if (lives < i) {
        livesArray.push(<FontAwesomeIcon key={i} icon={faHeartBroken} />);
      } else {
        livesArray.push(<FontAwesomeIcon key={i} icon={faHeart} />);
      }
    }
    return livesArray;
  };

  const renderProgess = useCallback(() => {
    if (
      [TriviaSteps.GAME_OVER, TriviaSteps.INTRO, TriviaSteps.AUTH].includes(
        currentStep
      )
    )
      return;
    const progressArray = [];
    const powerupKeyMap = Object.keys(powerupMap);

    for (let i = 0; i < MAX_QUESTIONS; i++) {
      let powerupIcon;
      if (answerHistory[i]) {
        let powerupUsed;
        if (answerHistory[i].powerupUsed) {
          const index = parseInt(answerHistory[i].powerupUsed!);
          powerupUsed = powerupKeyMap[index];
        }

        if (powerupUsed) {
          powerupIcon = powerupMap[powerupUsed as keyof typeof powerupMap].icon;
        }

        progressArray.push(
          <IconContainer key={i}>
            <ProgressIcon
              icon={
                powerupIcon
                  ? powerupIcon
                  : answerHistory[i].correct
                  ? faCheckCircle
                  : faXmarkCircle
              }
              fontSize={24}
            />
          </IconContainer>
        );
      } else {
        progressArray.push(
          <IconContainer key={i}>
            <ProgressIcon icon={faCircle} fontSize={24} />
          </IconContainer>
        );
      }
    }
    return progressArray;
  }, [answerHistory, currentStep]);

  const getQuestionPointValue = (
    difficulty: "easy" | "medium" | "hard" | ""
  ) => {
    const pointsMultiplier =
      currentPowerup?.key === PowerupTypes.DOUBLE_POINTS &&
      powerupState === "activated"
        ? 2
        : 1;
    if (difficulty === "medium") return BASE_POINTS * 2 * pointsMultiplier;
    if (difficulty === "hard") return BASE_POINTS * 4 * pointsMultiplier;
    return BASE_POINTS * pointsMultiplier;
  };

  const triggerPowerup = () => {
    switch (currentPowerup?.key) {
      case PowerupTypes.DOUBLE_POINTS:
        setPowerupState("activated");
        break;
      case PowerupTypes.LIFE_SAVER:
        setPowerupState("activated");
        setLives((prevLives) => prevLives + 1);
        break;
      case PowerupTypes.STREAK_SAVER:
        setPowerupState("activated");
        break;
      case PowerupTypes.SKIP_QUESTION:
        setPowerupState("activated");
        break;
      case PowerupTypes.FIFTY_FIFTY:
        setPowerupState("activated");
        break;
      default:
        break;
    }
  };

  const resetGame = () => {
    const triviaStats = localStorage.getItem("triviaStats");

    if (triviaStats) {
      localStorage.removeItem("triviaStats");
    }

    setCurrentStep(TriviaSteps.INTRO);
  };

  const setRandomPowerup = () => {
    const powerupKeys = Object.keys(powerupMap) as Array<
      keyof typeof powerupMap
    >;
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * powerupKeys.length);
      const randomPowerup =
        powerupMap[powerupKeys[randomIndex] as keyof typeof PowerupTypes];

      setCurrentPowerup({
        key: PowerupTypes[
          powerupKeys[randomIndex] as keyof typeof PowerupTypes
        ],
        icon: randomPowerup.icon,
        title: randomPowerup.title,
        description: randomPowerup.description,
      });
    }, 100);

    setTimeout(() => {
      setPowerupSelected(true);
      setPowerupState("available");
      clearInterval(interval);
    }, 3000);
  };

  const renderScore = () => {
    if (
      currentStep !== TriviaSteps.GAME_OVER &&
      currentStep !== TriviaSteps.INTRO &&
      currentStep !== TriviaSteps.AUTH
    )
      return (
        <>
          {streak > 0 && (
            <TriviaPoints>
              <FontAwesomeIcon icon={faFire} /> x{streak}
            </TriviaPoints>
          )}
          <TriviaPoints>Lives: {renderLives()}</TriviaPoints>
          <TriviaPoints>
            Score: {score.toString().padStart(3, "0")}
          </TriviaPoints>
        </>
      );
  };

  const renderBackButton = () => {
    if (
      [
        TriviaSteps.INTRO,
        TriviaSteps.ANSWER_QUESTION,
        TriviaSteps.GAME_OVER,
        TriviaSteps.GAME_WIN,
      ].includes(currentStep)
    )
      return;
    return (
      <IconButton
        onClick={handlePrevStep}
        icon={faArrowCircleLeft}
        size="2xl"
      />
    );
  };

  const renderTriviaStep = () => {
    if (showLoader) {
      return (
        <TriviaLoaderContainer>
          <CircleProgressLoader />
        </TriviaLoaderContainer>
      );
    }

    if (currentStep === TriviaSteps.INTRO) {
      return <Intro handleNextStep={handleNextStep} />;
    }

    if (currentStep === TriviaSteps.AUTH) {
      return (
        <AuthStep
          handleNextStep={handleNextStep}
          handleUnauthContinue={() => {
            setPowerupSelected(true);
            goToStep(TriviaSteps.SELECT_CATEGORY);
          }}
        />
      );
    }
    if (currentStep === TriviaSteps.POWERUP) {
      return (
        <PowerupStep
          powerup={currentPowerup}
          shufflePowerups={setRandomPowerup}
          handleNext={handleNextStep}
        />
      );
    }

    if (currentStep === TriviaSteps.SELECT_CATEGORY) {
      const handleCategorySelect = (category: TriviaCategoryProps) => {
        playBongSound();
        setCurrentCategory(category);
      };

      const handleDifficultySelect = (
        difficulty: "easy" | "medium" | "hard" | ""
      ) => {
        setDifficulty(difficulty);
        playBongSound();
      };

      return (
        <CategorySelect
          currentCategory={currentCategory}
          categories={categories}
          setCurrentCategory={(category: TriviaCategoryProps) =>
            handleCategorySelect(category)
          }
          selectedDifficulty={difficulty}
          selectDifficulty={(difficulty: "easy" | "medium" | "hard" | "") =>
            handleDifficultySelect(difficulty)
          }
          getQuestion={getQuestion}
          handleNextStep={() => setCurrentStep(TriviaSteps.ANSWER_QUESTION)}
          getQuestionPointValue={getQuestionPointValue}
          doublePointsAvailable={
            currentPowerup?.key === PowerupTypes.DOUBLE_POINTS &&
            (powerupState === "available" || powerupState === "activated")
          }
          triggerDoublePoints={triggerPowerup}
        />
      );
    }

    if (currentStep === TriviaSteps.ANSWER_QUESTION && currentQuestion) {
      return (
        <QuestionStep
          question={currentQuestion}
          answers={answers}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          handleSubmitAnswer={handleSubmitAnswer}
          playBongSound={playBongSound}
          startMusic={playBGMusic}
          stopMusic={stopBGMusic}
          currentPowerUp={currentPowerup}
          activatePowerup={triggerPowerup}
          skipKey={SKIP_KEY}
          powerupState={powerupState}
        />
      );
    }

    if (currentStep === TriviaSteps.ANSWER_RESULTS && currentQuestion) {
      const onResetQuiz = () => {
        setShowLoader(true);
        playConfirmSound();
        resetQuiz();
      };
      return (
        <ResultStep
          selectedAnswer={selectedAnswer}
          correctAnswer={currentQuestion.correct_answer}
          resetQuiz={onResetQuiz}
          addToScore={addToScore}
          answerHistory={answerHistory}
          setAnswerHistory={addToAnswerHistory}
          playCorrectSound={playCorrectSound}
          playIncorrectSound={playIncorrectSound}
          subtractLife={subtractLife}
          addStreak={addStreak}
          resetStreak={resetStreak}
          powerupState={powerupState}
          currentPowerUp={currentPowerup}
          activatePowerup={triggerPowerup}
          currentStreak={streak}
          currentLives={lives}
          skipKey={SKIP_KEY}
        />
      );
    }
    if (currentStep === TriviaSteps.GAME_OVER) {
      return <GameOver resetSteps={resetGame} />;
    }
  };

  return (
    <TriviaPageRoot id="trivia-root">
      <TriviaPointsContainer>{renderScore()}</TriviaPointsContainer>
      <TriviaContainer id="trivia-container">
        {!showLoader && <TriviaControls>{renderBackButton()}</TriviaControls>}
        {renderTriviaStep()}
      </TriviaContainer>
      <TriviaProgressContainer>
        <>{renderProgess()}</>
      </TriviaProgressContainer>
    </TriviaPageRoot>
  );
};

const TriviaPageRoot = styled.div`
  height: calc(100vh - 64px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px 16px;
`;

const TriviaPointsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 1000px;
  min-height: 50px;
  padding: 8px 16px;
  gap: 16px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TriviaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 1000px;
  min-height: 600px;
  border-radius: 8px;
  background: linear-gradient(#008080, #4cb6b6);
  border: #009797 solid 2px;
  transition: height 1s ease;

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    min-width: unset;
    min-height: unset;
  }
`;

const TriviaControls = styled.div`
  margin: 16px;
  padding: 0 16px;
  display: flex;
  width: 100%;
  min-height: 32px;
`;

const TriviaProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  min-height: 66px;
  padding: 8px 16px;
  gap: 16px;
  @media (max-width: 768px) {
    width: 100%;
    min-height: 16px;
    justify-content: space-evenly;
    gap: 12px;
  }
`;

const TriviaLoaderContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }
`;

const ProgressIcon = styled(FontAwesomeIcon)`
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const TriviaPoints = styled.div`
  color: white;
  font-size: 24px;
  font-family: "Tilt Neon";
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export default TriviaMain;
