import AuthPage from "pages/Auth";
import LandingPage from "pages/LandingPage/LandingPage";
import HomePage from "pages/HomePage/HomePage";
import React from "react";
import UnityStateProvider from "context/UnityContext/UnityContext";
import TriviaPage from "pages/TriviaPage";
import DevPlayfab from "pages/Dev/DevPlayfab";
import Avatar from "pages/Avatar";

export const RequireAuth: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // const { isAuthorized, setIsAuthorized, setplayerData } = useAuth();
  // const location = useLocation();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isAuthorized) {
  //     const playerData = localStorage.getItem("playerData");

  //     if (playerData) {
  //       setplayerData(JSON.parse(playerData));
  //       setIsAuthorized(true);
  //     } else {
  //       navigate("/login");
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return <>{children}</>;
};

const routes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/login",
    element: <AuthPage />,
  },
  {
    path: "/register",
    element: <AuthPage />,
  },
  {
    path: "/trivia",
    element: <TriviaPage />,
  },
  {
    path: "/test",
    element: <DevPlayfab />,
  },
  {
    path: "/avatar",
    element: <Avatar />,
  },
  {
    path: "/avatar/:avatarState",
    element: <Avatar />,
  },
  {
    path: "/home",
    element: (
      <RequireAuth>
        <UnityStateProvider>
          <HomePage />
        </UnityStateProvider>
      </RequireAuth>
    ),
  },
];

export default routes;
