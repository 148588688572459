import React from "react";
import { useAuth } from "context/Auth";
import { useUnity } from "context/UnityContext/UnityContext";
import Button from "common/Button";
import styled from "styled-components";
import useLayout from "utilities/hooks/useLayout";
import DesktopLayout from "./components/DesktopLayout";
import MobileLayout from "./components/MobileLayout";
// import HeaderMessage from "./components/HeaderMessage";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { width } = useLayout();
  const { unload } = useUnity();
  const { logout } = useAuth();

  const [showLogoutModal, setShowLogoutModal] = React.useState(false);

  const handleCloseLogout = () => setShowLogoutModal(false);
  const handleShowLogout = () => setShowLogoutModal(true);

  const handleLogout = async () => {
    try {
      await unload();
    } catch (error) {
      console.error("Error unloading Unity", error);
    }
    logout();
  };

  return (
    <LayoutRoot>
      {width > 600 ? (
        <DesktopLayout handleShowLogout={handleShowLogout} />
      ) : (
        <MobileLayout handleShowLogout={handleShowLogout} />
      )}

      {showLogoutModal && (
        <div
          style={{
            position: "absolute",
            zIndex: 9999,
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#0e0e0e99",
          }}
        >
          <div>Are you sure you want to logout?</div>
          <div
            style={{
              width: "200px",
              display: "flex",
              justifyContent: "space-around",
              marginTop: "16px",
            }}
          >
            <Button onClick={handleCloseLogout}>No</Button>
            <Button onClick={handleLogout}>Yes</Button>
          </div>
        </div>
      )}
      {children}
    </LayoutRoot>
  );
};
const LayoutRoot = styled.div``;

export default Layout;
