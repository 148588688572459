import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle as faCheckCircleRegular,
  faXmarkCircle as faXmarkCircleRegular,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TriviaQuestion } from "actions/types";
import { useEffect, useRef, useState } from "react";
import { Accordion, Badge, Button } from "react-bootstrap";
import styled from "styled-components";
import { decodeHtmlEntities } from "utilities";
import { powerupMap } from "../TriviaMain";

interface Props {
  resetSteps: () => void;
}

const GameOver = ({ resetSteps }: Props) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [showStats, setShowStats] = useState<boolean>(false);
  const [triviaStats, setTriviaStats] = useState<{
    answerHistor: any;
    lives: number;
    score: number;
    streak: number;
  } | null>(null);
  const [answerHistory, setAnswerHistory] = useState<
    {
      question: TriviaQuestion;
      answer: string;
      correct: boolean;
      pointsAwarded: number;
      powerupUsed?: "string";
    }[]
  >([]);
  const targetDate = useRef<Date>(new Date());

  useEffect(() => {
    let triviaResetDate = new Date();
    const storedResetDate = localStorage.getItem("triviaResetDate");
    if (!storedResetDate) {
      triviaResetDate = new Date();
      triviaResetDate.setDate(triviaResetDate.getDate() + 1);
      triviaResetDate.setHours(0, 0, 0, 0);
      localStorage.setItem("triviaResetDate", triviaResetDate.toString());
    } else {
      targetDate.current = new Date(storedResetDate);
    }

    const storedTriviaStats = localStorage.getItem("triviaStats");
    if (storedTriviaStats) {
      const trisetAnswerHistory = JSON.parse(storedTriviaStats);
      setAnswerHistory(trisetAnswerHistory.answerHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const targetTime = targetDate.current.getTime();
      const currentTime = new Date().getTime();
      const timeDiff = targetTime - currentTime;
      if (timeDiff <= 0) {
        clearInterval(intervalId);
        setTimeRemaining(0);
        resetSteps();
      } else {
        setTimeRemaining(timeDiff);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem("triviaStats")) {
      setTriviaStats(JSON.parse(localStorage.getItem("triviaStats") || ""));
    }
  }, []);

  const formatTime = (ms: number) => {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 3600) % 24);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  const powerupKeyMap = Object.keys(powerupMap);

  return (
    <GameOverContainer>
      <GameOverTitle>Trivia Completed</GameOverTitle>
      {showStats ? (
        <GameStatContainer>
          <div
            style={{
              height: "100%",
            }}
          >
            <Button
              style={{ marginBottom: "16px" }}
              onClick={() => setShowStats((p) => !p)}
              variant="light"
            >
              Back
            </Button>
            <Accordion style={{ paddingBottom: "60px" }}>
              {answerHistory.map(
                (
                  { question, answer, correct, pointsAwarded, powerupUsed },
                  index
                ) => {
                  let powerupIcon;
                  if (powerupUsed) {
                    const index = parseInt(powerupUsed!);

                    powerupIcon =
                      powerupMap[
                        powerupKeyMap[index] as keyof typeof powerupMap
                      ].icon;
                  }

                  return (
                    <Accordion.Item
                      key={`stats-${index}`}
                      eventKey={`${index}`}
                    >
                      <Accordion.Header
                        style={{
                          borderRadius: "6px",
                          border: "1px solid #ffffff00",
                        }}
                      >
                        <div style={{ fontSize: 18, marginRight: "16px" }}>
                          {correct ? (
                            <FontAwesomeIcon
                              style={{ marginRight: "8px" }}
                              key={"correct-" + index}
                              icon={faCheckCircleRegular}
                            />
                          ) : powerupUsed && powerupIcon ? (
                            <FontAwesomeIcon
                              style={{ marginRight: "8px" }}
                              key={"correct-" + index}
                              icon={powerupIcon}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{ marginRight: "8px" }}
                              key={"correct-" + index}
                              icon={faXmarkCircleRegular}
                            />
                          )}
                          {decodeHtmlEntities(question.question)}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ borderRadius: "6px" }}>
                        <GameOverAnswerHistoryQuestionContainer key={index}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Badge
                                style={{ marginRight: "8px" }}
                                pill
                                bg="secondary"
                              >
                                {question.difficulty.charAt(0).toUpperCase() +
                                  question.difficulty.slice(1)}
                              </Badge>
                              <span>{question.category}</span>
                            </div>
                            <div>
                              {correct ? `+${pointsAwarded} points` : ""}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                margin: "8px 0",
                              }}
                            >
                              Answer: {answer}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {correct ? (
                                <>
                                  <FontAwesomeIcon
                                    style={{
                                      marginRight: "8px",
                                      color: "green",
                                    }}
                                    key={"correct-" + index}
                                    icon={faCheckCircle}
                                  />
                                  <span
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 2,
                                      color: "green",
                                    }}
                                  >
                                    Answered correctly
                                  </span>
                                </>
                              ) : powerupUsed && powerupIcon ? (
                                <>
                                  <FontAwesomeIcon
                                    key={"correct-" + index}
                                    icon={faXmarkCircle}
                                    style={{ marginRight: "8px", color: "red" }}
                                  />
                                  <span
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 2,
                                      color: "red",
                                    }}
                                  >
                                    Answered incorrectly
                                  </span>
                                </>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    key={"correct-" + index}
                                    icon={faXmarkCircle}
                                    style={{ marginRight: "8px", color: "red" }}
                                  />
                                  <span
                                    style={{
                                      fontSize: 14,
                                      lineHeight: 2,
                                      color: "red",
                                    }}
                                  >
                                    Answered incorrectly
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </GameOverAnswerHistoryQuestionContainer>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              )}
            </Accordion>
          </div>
        </GameStatContainer>
      ) : (
        <GameTimerContainer>
          <GameOverText>Try again tomorrow</GameOverText>
          {timeRemaining > 0 && (
            <>
              <GameOverCountdownTitle>Time remaining:</GameOverCountdownTitle>
              <GameOverCountdownText>
                {formatTime(timeRemaining)}
              </GameOverCountdownText>
            </>
          )}
          {triviaStats && (
            <>
              <GameOverCountdownTitle>Stats:</GameOverCountdownTitle>
              <GameOverStatsText>
                <div>Final Score: {triviaStats.score}</div>
                <div>Streak: {triviaStats.streak}</div>
                <div>Lives: {triviaStats.lives}</div>
              </GameOverStatsText>
            </>
          )}
          <GameOverStatsButton
            onClick={() => setShowStats((p) => !p)}
            variant="light"
          >
            View Stats
          </GameOverStatsButton>
        </GameTimerContainer>
      )}
    </GameOverContainer>
  );
};

const GameOverContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  height: 600px;
  max-height: 600px;
  @media (max-width: 768px) {
    height: unset;
  }
`;

const GameTimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GameStatContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 680px;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  margin: 0 0 32px;
  padding: 16px 0;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto 32px;
  }
`;

const GameOverTitle = styled.div`
  font-size: 64px;
  font-family: "Luckiest Guy";
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
const GameOverText = styled.div`
  color: white;
  font-size: 36px;
  font-family: "Tilt Neon";
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;
const GameOverCountdownTitle = styled.div`
  font-family: "Tilt Neon";
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const GameOverCountdownText = styled.div`
  font-family: "Tilt Neon";
  font-size: 64px;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  text-align: center;
  width: 100%;
  min-width: 400px;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
const GameOverStatsText = styled.div`
  text-align: center;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const GameOverAnswerHistoryQuestionContainer = styled.div``;
const GameOverStatsButton = styled(Button)`
  margin-top: 24px;
  padding: 8px 32px;
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export default GameOver;
