import React, { useState } from "react";

import { useAuth } from "context/Auth";

import { isValidEmail } from "utilities";
import AuthPageStyles from "../AuthPage.styles";

const LoginForm = () => {
  const {
    authErrorMessage,
    setAuthErrorMessage,
  } = useAuth();
  const [formState, setFormState] = useState({
    username: "",
    password: "",
  });
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = () => {
    if (formState.username.includes("@")) {
      // might be an email
      const isEmail = isValidEmail(formState.username);
      if (isEmail) {
        console.log("submitting to login function");
        // TODO Add login function here
        return;
      }
      setAuthErrorMessage(
        "Looks like the email you entered is not formatted right, try again."
      );
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formState.username.includes("@")) {
      // might be an email
      const isEmail = isValidEmail(formState.username);
      if (isEmail) {
        console.log("submitting to login function");
        // TODO Add login function here
        return;
      }
      setAuthErrorMessage(
        "Looks like the email you entered is not formatted right, try again."
      );
    }
  };
  return (
    <AuthPageStyles.InputContainer>
      <AuthPageStyles.FormTitle>Enter your login info</AuthPageStyles.FormTitle>
      <AuthPageStyles.FormSubtitle>
        Enter your username and password to jump back into the action
      </AuthPageStyles.FormSubtitle>

      <form onSubmit={handleSubmit}>
        <AuthPageStyles.FormUsernameInput
          id="inputUsername"
          placeholder="Username"
          value={formState.username}
          name="username"
          onChange={handleInput}
        />

        <AuthPageStyles.FormPasswordInput
          id="inputPassword"
          type="password"
          placeholder="Password"
          value={formState.password}
          name="password"
          onChange={handleInput}
        />
        <AuthPageStyles.FormErrorMessage>
          {authErrorMessage}
        </AuthPageStyles.FormErrorMessage>
      </form>

      <AuthPageStyles.FormSubmitButton onClick={handleLogin}>
        Enter a &#x03BC;Realm
      </AuthPageStyles.FormSubmitButton>
    </AuthPageStyles.InputContainer>
  );
};

export default LoginForm;
