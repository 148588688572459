import {
  faCheckCircle,
  faCircleXmark,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TriviaQuestion } from "actions/types";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { decodeHtmlEntities } from "utilities";
import { PowerupProps, PowerupTypes } from "./TriviaMain";

type NewType = {
  selectedAnswer: string;
  correctAnswer: string;
  resetQuiz: () => void;
  addToScore: () => void;
  answerHistory: {
    question: TriviaQuestion;
    answer: string;
    pointsAwarded: number;
    correct: boolean;
  }[];
  setAnswerHistory: (
    answer: string,
    correct: boolean,
    powerup?: string
  ) => void;
  playCorrectSound: () => void;
  playIncorrectSound: () => void;
  subtractLife: () => void;
  addStreak: () => void;
  resetStreak: () => void;
  currentPowerUp: PowerupProps | null;
  activatePowerup: () => void;
  powerupState: "unselected" | "available" | "activated" | "used";
  currentStreak: number;
  currentLives: number;
  skipKey: string;
};

type AnswerResultProps = NewType;

const ResultStep = ({
  selectedAnswer,
  correctAnswer,
  resetQuiz,
  addToScore,
  answerHistory,
  setAnswerHistory,
  playCorrectSound,
  playIncorrectSound,
  subtractLife,
  resetStreak,
  addStreak,
  currentPowerUp,
  activatePowerup,
  powerupState,
  currentStreak,
  currentLives,
  skipKey,
}: AnswerResultProps) => {
  const [hasActivatedPowerup, setHasActivatedPowerup] = useState(false);
  const hasAddedToScore = useRef(false);
  const hasPlayedSound = useRef(false);
  const isCorrectAnswer = selectedAnswer === correctAnswer;
  const userHasSkipped = selectedAnswer === skipKey;
  const powerupUsedKey =
    currentPowerUp && powerupState === "activated"
      ? currentPowerUp.key.toString()
      : "";

  useEffect(() => {
    if (hasPlayedSound.current === true) return;
    if (userHasSkipped) {
      playCorrectSound();
      setAnswerHistory(correctAnswer, isCorrectAnswer, powerupUsedKey);
      hasPlayedSound.current = true;
      return;
    }
    if (isCorrectAnswer) {
      playCorrectSound();
      addStreak();
    } else {
      playIncorrectSound();
      subtractLife();
    }
    setAnswerHistory(correctAnswer, isCorrectAnswer, powerupUsedKey);
    hasPlayedSound.current = true;
  }, [
    selectedAnswer,
    setAnswerHistory,
    correctAnswer,
    playCorrectSound,
    playIncorrectSound,
    subtractLife,
    addStreak,
    isCorrectAnswer,
    powerupUsedKey,
    userHasSkipped,
  ]);

  useEffect(() => {
    if (isCorrectAnswer && hasAddedToScore.current === false) {
      hasAddedToScore.current = true;
      addToScore();
    }
  }, [
    selectedAnswer,
    correctAnswer,
    addToScore,
    setAnswerHistory,
    isCorrectAnswer,
  ]);

  const onActivatePowerup = () => {
    if (hasActivatedPowerup) return;
    setHasActivatedPowerup((p) => {
      if (!p) activatePowerup();
      return !p;
    });
  };

  const shouldShowPowerupButton = () => {
    if (currentPowerUp === null) return false;
    if (currentPowerUp.key === PowerupTypes.DOUBLE_POINTS) {
      return (
        !isCorrectAnswer &&
        !userHasSkipped &&
        currentPowerUp &&
        powerupState !== "used" &&
        powerupState !== "unselected"
      );
    }
    if (currentStreak > 1 && currentPowerUp.key === PowerupTypes.STREAK_SAVER) {
      return (
        !isCorrectAnswer &&
        !userHasSkipped &&
        currentPowerUp &&
        powerupState !== "used" &&
        powerupState !== "unselected" &&
        currentLives >= 1
      );
    }
    return false;
  };

  const onResetQuiz = () => {
    resetQuiz();
    if (!isCorrectAnswer && !userHasSkipped) resetStreak();
  };

  const doublePointsModifier =
    answerHistory[answerHistory.length - 1]?.pointsAwarded;
  return (
    <CorrectAnswerContainer>
      <CorrectAnswerTitle>
        {isCorrectAnswer ? "Correct" : "Incorrect"}
      </CorrectAnswerTitle>
      {selectedAnswer === "" && (
        <NoSelectedAnswerText>
          You didn't select an answer!
        </NoSelectedAnswerText>
      )}
      <ResultStepIcon
        icon={
          isCorrectAnswer
            ? faCheckCircle
            : userHasSkipped
            ? faForward
            : faCircleXmark
        }
      />
      <CorrectAnswerSubtitle>
        {isCorrectAnswer
          ? "You selected the right answer!"
          : userHasSkipped
          ? "Question Skipped, the answer was"
          : "The correct answer was"}
      </CorrectAnswerSubtitle>
      <CorrectAnswerText>{decodeHtmlEntities(correctAnswer)}</CorrectAnswerText>

      {shouldShowPowerupButton() && (
        <NextButton
          variant="light"
          disabled={hasActivatedPowerup}
          onClick={onActivatePowerup}
        >
          {currentPowerUp?.icon && (
            <FontAwesomeIcon icon={currentPowerUp?.icon} />
          )}
          {hasActivatedPowerup
            ? ` ${currentPowerUp?.title} activated `
            : ` Activate ${currentPowerUp?.title} `}
          {currentPowerUp?.icon && (
            <FontAwesomeIcon icon={currentPowerUp?.icon} />
          )}
        </NextButton>
      )}
      {isCorrectAnswer && (
        <CorrectAnswerPointsText>{`+${doublePointsModifier} Points`}</CorrectAnswerPointsText>
      )}
      <NextButton variant="light" onClick={onResetQuiz}>
        Next
      </NextButton>
    </CorrectAnswerContainer>
  );
};

const CorrectAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 64px;
  width: 100%;
  max-width: 400px;
  .enabled {
    border: 2px solid black;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const NoSelectedAnswerText = styled.div`
  font-family: "Tilt Neon";
  font-size: 20px;
  margin: 16px 0;
`;

const CorrectAnswerTitle = styled.div`
  font-family: "Luckiest Guy";
  font-size: 48px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const ResultStepIcon = styled(FontAwesomeIcon)`
  margin: 16px;
  font-size: 140px;
  @media (max-width: 768px) {
    font-size: 108px;
  }
`;

const CorrectAnswerSubtitle = styled.div`
  font-family: "Tilt Neon";
  font-size: 20px;
  margin: 16px 0;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CorrectAnswerText = styled.div`
  font-family: "Tilt Neon";
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const CorrectAnswerPointsText = styled.div`
  font-family: "Tilt Neon";
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const NextButton = styled(Button)`
  margin-top: 24px;
  padding: 8px 48px;
  font-size: 20px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export default ResultStep;
