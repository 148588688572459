import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

export type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

const breakpointMap: { [key: string]: number } = {
  xs: 320,
  sm: 425,
  md: 768,
  lg: 1024,
  xl: 1440
};

const resolveBreakpoint = (width: number): Breakpoint => {
  if (width < 320) return "xs";
  if (width < 425) return "sm";
  if (width < 768) return "md";
  if (width < 1024) return "lg";
  if (width < 1440) return "xl";
  return "xxl";
};

export const useBreakpoint = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  const update = useDebouncedCallback(() => {
    setSize(resolveBreakpoint(window.innerWidth));
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  }, [update]);

  return size;
};
export const useLessthanBreakpoint = (targetBreakpoint: string) => {
  const [size, setSize] = useState(window.innerWidth <= breakpointMap[targetBreakpoint]);
  const update = useDebouncedCallback(() => {
    setSize(window.innerWidth <= breakpointMap[targetBreakpoint]);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  }, [update]);

  return size;
};
