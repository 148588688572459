import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { devices } from "index.styles";

const LandingPageStyles = {
  Root: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    background: linear-gradient(48deg, #1b23d5, #199fff);
    @media ${devices.tablet} {
      flex-direction: column;
    }
    @media ${devices.mobileL} {
    }
  `,
  LoadingOverlay: styled.div`
    position: absolute;
    background-color: #ffffff77;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 18px;
    font-weight: bold;
  `,
  HeroContainer: styled.div`
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 32px 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${devices.laptop} {
      padding: 0;
    }
  `,
  TextContainer: styled.div`
    height: 25%;
    min-height: 244px;
    width: 480px;
    @media ${devices.laptop} {
      width: 100%;
      margin: 0 auto;
      padding: 16px 32px 0;
    }
  `,
  TitleText: styled.div`
    font-family: Bungee;
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 12px;
    @media ${devices.mobileL} {
      font-size: 54px;
      margin-bottom: 24px;
      text-align: center;
    }
  `,
  ExplainationText: styled.div`
    font-family: Poppins;
    font-size: 16px;
    margin-bottom: 16px;
    @media ${devices.mobileL} {
      font-size: 14px;
      margin-bottom: 24px;
    }
  `,
  SubtitleText: styled.div`
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.5px;
    @media ${devices.mobileL} {
      font-size: 14px;
      margin-bottom: 16px;
    }
  `,
  HeroImageContainer: styled.div`
    height: 90%;
    display: flex;
    margin-top: -64px;
    @media ${devices.tablet} {
      width: calc(100% + 64px);
      margin-left: -32px;
      margin-bottom: -18px;
      height: 100%;
    }
  `,
  HeroImage: styled.img`
    max-width: 100%;
    max-height: 100%;
    margin: auto auto;
    @media ${devices.mobileL} {
      margin: auto auto 0;
    }
  `,
  HeroMobileImage: styled.img`
    max-width: 600px;
    width: 100%;
    max-height: 100%;
    margin: auto auto 0;
  `,
  PoweredByImage: styled.img`
    max-width: 180px;
    position: absolute;
    z-index: 99999;
    bottom: 8px;
    right: 8px;
    width: 100%;
    height: auto;
    @media ${devices.laptop} {
      opacity: 85%;
      max-width: 120px;
    }
  `,
  FormContainer: styled.div`
    padding: 32px;
    height: 100vh;
    width: 60%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.palette.common.black.main};
    @media ${devices.laptop} {
      width: 100%;
    }
    @media ${devices.tablet} {
      position: absolute;
      top: 244px;
      left: 50%;
      transform: translate(calc(-50% - 16px));
      z-index: 999;
      height: auto;
      width: calc(100% - 32px);
      max-width: unset;
      border-radius: 16px;
      background-color: ${({ theme }) => theme.palette.common.black.main}88;
      margin: 16px;
    }
    @media ${devices.mobileL} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 16px), -50%);
      z-index: 999;
      height: auto;
      width: calc(100% - 32px);
      max-width: unset;
      border-radius: 16px;
      background-color: ${({ theme }) => theme.palette.common.black.main}88;
      margin: 16px;
    }
  `,
  InputContainer: styled.div`
    width: 100%;
  `,
  FormTitle: styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    @media ${devices.mobileL} {
      font-size: 18px;
    }
  `,
  FormSubtitle: styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.palette.common.grey.main};
    margin-bottom: 32px;
    text-align: left;
    @media ${devices.tablet} {
      color: ${({ theme }) => theme.palette.common.white.main}88;
      font-size: 14px;
      margin-bottom: 16px;
    }
  `,
  FormLocalUserExists: styled.div`
    height: 38px;
    width: 100%;
    text-align: left;
    padding: 4px 8px 4px 12px;
    margin: 0 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.palette.common.grey.dark};
    @media ${devices.mobileL} {
      margin: 0 0 8px;
    }
  `,
  EditLocalUsername: styled(Button)`
    border-radius: 50%;
    padding: 0px 4px;
    font-size: 16px;
    color: black;
    background-color: ${({ theme }) => theme.palette.common.white.main}99;
    border-color: ${({ theme }) => theme.palette.common.white.main}88;
    &:hover {
      background-color: black;
      border-color: black;
      color: white;
    }
  `,
  FormUsernameInput: styled(Form.Control)`
    background-color: grey;
    border: 1px solid ${({ theme }) => theme.palette.common.grey.dark};
    color: white;

    ::placeholder {
      color: white;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.common.grey.dark}
        inset !important;
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.palette.common.grey.dark} inset !important;
      -webkit-text-fill-color: white !important;
    }
  `,
  FormErrorMessage: styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.palette.common.error.main};
    text-align: left;
    font-weight: bold;
    min-height: 18px;
    margin: 8px 0 12px;
  `,
  FormSubmitButton: styled(Button)`
    width: 100%;
    @media ${devices.mobileL} {
      margin-bottom: 12px;
    }
  `,
  FormFooter: styled.div`
    display: flex;
    font-size: 14px;

    justify-content: space-between;
    a {
      text-decoration: none;
    }
  `,
};

export default LandingPageStyles;
