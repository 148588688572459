import styled from "styled-components";
import { useAuth } from "context/Auth";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Text from "common/Text";

interface Props {
  handleNextStep: () => void;
  handleUnauthContinue: () => void;
}

const AuthStep = ({ handleNextStep, handleUnauthContinue }: Props) => {
  const { isAuthorized, isAnonLoggedIn, playerData } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    localStorage.setItem("returnPath", "/trivia");
    navigate("/login");
  };
  // const handleRegister = () => {
  //   localStorage.setItem("returnPath", "/trivia");
  //   navigate("/login");
  // };

  const handleContinue = () => {
    handleNextStep();
  };

  if (isAnonLoggedIn) {
    return (
      <AnonContainer>
        <div style={{ marginTop: "64px" }}>
          <StyledTitle variant="trivia" type="title">
            Your account is not registered
          </StyledTitle>
          <StyledTitle variant="trivia" type="subtitle">
            If you would like to keep track of your points, please complete
            registration
          </StyledTitle>
        </div>
        <ButtonContainer>
          <StyledButton variant="light" onClick={handleLogin}>
            Complete Registration
          </StyledButton>
          <StyledButton variant="light" onClick={handleContinue}>
            Continue anyway
          </StyledButton>
        </ButtonContainer>
      </AnonContainer>
    );
  }

  if (isAuthorized) {
    return (
      <AnonContainer>
        <div style={{ marginTop: "64px" }}>
          {playerData?.username && (
            <Text variant="trivia" type="title">
              Welcome, {playerData.username}
            </Text>
          )}
          <StyledTitle variant="trivia" type="subtitle">
            You are logged in
          </StyledTitle>
        </div>
        <ButtonContainer>
          <StyledButton variant="light" onClick={handleContinue}>
            Continue
          </StyledButton>
        </ButtonContainer>
      </AnonContainer>
    );
  }

  return (
    <AnonContainer>
      <div style={{ marginTop: "64px" }}>
        <Text variant="trivia" type="title">
          Not logged in
        </Text>
        <StyledTitle variant="trivia" type="subtitle">
          Would you like to play anyway?
        </StyledTitle>
        <StyledTitle variant="trivia" type="subtitle">
          Your history will not be saved, and your score will not be redeemable
        </StyledTitle>
        <StyledTitle variant="trivia" type="subtitle">
          Or you can register/ log in to keep track of your points
        </StyledTitle>
      </div>
      <ButtonContainer>
        <StyledButton variant="light" onClick={handleLogin}>
          Log In
        </StyledButton>
        <StyledButton variant="light" onClick={handleUnauthContinue}>
          Continue anyway
        </StyledButton>
      </ButtonContainer>
    </AnonContainer>
  );
};
export default AuthStep;

const AnonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledTitle = styled(Text)`
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto 16px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 64px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledButton = styled(Button)`
  min-width: 200px;
  padding: 8px 16px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
