import { IColorOptions } from "styled";
import styled, { useTheme } from "styled-components";

interface TextProps {
  children: React.ReactNode;

  style?: React.CSSProperties;
  variant?: "primary" | "secondary" | "tertiary" | "trivia";
  type?: "title" | "subtitle" | "body" | "caption";
}

const Text = (props: TextProps) => {
  const { children, style, variant, type } = props;
  const theme = useTheme();
  const parseVariantStyling = () => {
    if (
      variant &&
      theme.palette.common[variant as keyof typeof theme.palette.common]
    ) {
      return theme.palette.common[variant as keyof typeof theme.palette.common];
    }
    return theme.palette.common.primary;
  };
  switch (variant) {
    case "primary":
      return (
        <PrimaryStyledText
          {...props}
          style={style}
          variant={parseVariantStyling()}
          type={type}
        >
          {children}
        </PrimaryStyledText>
      );
    case "secondary":
      return (
        <SecondaryStyledText
          {...props}
          style={style}
          variant={parseVariantStyling()}
          type={type}
        >
          {children}
        </SecondaryStyledText>
      );
    case "tertiary":
      return (
        <TertiaryStyledText
          {...props}
          style={style}
          variant={parseVariantStyling()}
          type={type}
        >
          {children}
        </TertiaryStyledText>
      );
    case "trivia":
      return (
        <TriviaStyledText
          {...props}
          style={style}
          variant={parseVariantStyling()}
          type={type}
        >
          {children}
        </TriviaStyledText>
      );
    default:
      return (
        <StyledText
          {...props}
          style={style}
          variant={parseVariantStyling()}
          type={type}
        >
          {children}
        </StyledText>
      );
  }
};

interface StyledProps {
  variant: IColorOptions;
  type?: "hero" | "title" | "subtitle" | "body" | "caption";
}

const StyledText = styled.div<StyledProps>`
  font-size: 16px;
  color: ${({ variant }) => variant.main};
`;

const PrimaryStyledText = styled.div<StyledProps>`
  font-size: 16px;
  color: ${({ variant }) => variant.main};
`;

const SecondaryStyledText = styled.div<StyledProps>`
  font-size: 16px;
  color: ${({ variant }) => variant.main};
`;

const TertiaryStyledText = styled.div<StyledProps>`
  font-size: 16px;
  color: ${({ variant }) => variant.main};
`;

const TriviaStyledText = styled.div<StyledProps>`
  font-size: 16px;
  color: ${({ variant }) => variant.main};
  ${({ type }) => {
    switch (type) {
      case "hero":
        return `
          font-family: "Luckiest Guy";
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          `;
      case "title":
        return `
            font-family: "Tilt Neon";
            font-size: 26px;
            text-align: center;
            `;
      case "subtitle":
        return `
            font-family: "Tilt Neon";
            font-size: 20px;
            text-align: center;
        `;
      case "body":
        return `
          font-family: "Tilt Neon";
          font-size: 16px;
        `;
      case "caption":
        return `
          font-family: "Tilt Neon";
          font-size: 14px;
        `;
      default:
        return `
          font-family: "Tilt Neon";
          font-size: 16px;
        `;
    }
  }};
`;
export default Text;
