import React from "react";
import Button from "react-bootstrap/Button";
import { IColorOptions } from "styled";
import styled, { useTheme } from "styled-components";

interface Props {
  color?: "primary" | "trivia-primary";
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const CustomButton = ({ color, children, onClick, disabled }: Props) => {
  if (color === undefined) {
    color = "primary";
  }
  const theme = useTheme();
  const colorMap = {
    primary: theme.palette.primary,
    "trivia-primary":
      theme.palette.common[
        "trivia-primary" as keyof typeof theme.palette.common
      ],
  };

  return (
    <StyledButton
      onClick={onClick}
      color={colorMap[color as keyof typeof colorMap]}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ color: IColorOptions }>`
  background-color: ${(props) => props.color.main};
  border-color: ${(props) => props.color.main};
  color: ${(props) => props.color.text};
  &:hover {
    background-color: ${(props) => props.color.dark};
    border-color: ${(props) => props.color.dark};
  }
  &:active {
    background-color: ${(props) => props.color.darker} !important;
    border-color: ${(props) => props.color.darker} !important;
  }

  &:focus {
    background-color: ${(props) => props.color.darker} !important;
    border-color: ${(props) => props.color.darker} !important;
  }
  &:disabled {
    background-color: ${(props) => props.color.light} !important;
    border-color: ${(props) => props.color.light} !important;
  }
`;

export default CustomButton;
// .custom-button {
//     background-color: #ff5733; /* Custom background color */
//     border-color: #ff5733; /* Custom border color */
//     color: #fff; /* Custom text color */
//   }

//   .custom-button:hover {
//     background-color: #e63c00; /* Custom background color on hover */
//     border-color: #e63c00; /* Custom border color on hover */
//   }

//   .custom-button:active {
//     background-color: #cc2900; /* Custom background color on click */
//     border-color: #cc2900; /* Custom border color on click */
//   }
