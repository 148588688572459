import styled from "styled-components";
import { decodeHtmlEntities } from "utilities";

interface Props {
  question: string;
}

const Question = ({ question }: Props) => {
  return (
    <TriviaQuestionGradient>
      <TriviaQuestionText>{decodeHtmlEntities(question)}</TriviaQuestionText>
    </TriviaQuestionGradient>
  );
};

const TriviaQuestionText = styled.div`
  background-color: #fff;
  padding: 28px 32px;
  width: 100%;
  font-size: 28px;

  border-radius: 6px;
  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 18px;
  }
`;
const TriviaQuestionGradient = styled.div`
  position: relative;
  margin: 12px 0px 24px;
  width: 80%;

  background: #fff;
  text-align: center;
  color: #000;
  border-radius: 8px;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 90%;
  }

  &:after {
    content: "";
    position: absolute;
    top: calc(-1 * 3px);
    left: calc(-1 * 3px);
    height: calc(100% + 3px * 2);
    width: calc(100% + 3px * 2);
    background: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
    border-radius: 8px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;

    @keyframes animatedgradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
`;

export default Question;
