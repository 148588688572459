import Layout from "components/Layout";
import ReadyPlayerMeFrame from "components/ReadyPlayerMeFrame";
import { useAuth } from "context/Auth";

const Avatar = () => {
  const { playerData } = useAuth();
  if (!playerData?.avatarModelURL) {
    return <ReadyPlayerMeFrame />;
  }
  return (
    <Layout>
      <div></div>
    </Layout>
  );
};

export default Avatar;
