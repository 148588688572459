import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { decodeHtmlEntities } from "utilities";

interface Props {
  answers: string[];
  selectedAnswer: string;
  setSelectedAnswer: (answer: string) => void;
}

const Answers = ({ answers, selectedAnswer, setSelectedAnswer }: Props) => {
  return (
    <AnswerContainer>
      {answers.map((answer, i) => {
        return (
          <AnswerButton
            key={`answer-${i}-` + answer}
            variant="light"
            disabled={selectedAnswer === answer}
            onClick={() => setSelectedAnswer(answer)}
          >
            {decodeHtmlEntities(answer)}
          </AnswerButton>
        );
      })}
    </AnswerContainer>
  );
};

const AnswerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-between;
  align-items: stretch;
  font-family: "Tilt Neon";
  gap: 8px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const AnswerButton = styled(Button)`
  width: calc(50% - 4px);
  height: 80px;
  font-size: 24px;
  padding: 8px 16px;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 4px 8px;
    height: 70px;
  }
`;

export default Answers;
