import React from "react";
import { useUnity } from "context/UnityContext/UnityContext";
import { Unity } from "react-unity-webgl";
import styled from "styled-components";
import CircleProgressLoader from "components/CircleProgressLoader";
// import { UnityStyles } from './UnityLayer.styles';

const GameEnvironment: React.FC = () => {
  const { unityProvider, isLoading } = useUnity();

  return (
    <UnityContainer id="unity-layer-container" className="wrapper">
      {isLoading && (
        <LoadingScreen>
          <CircleProgressLoader useMessages />
        </LoadingScreen>
      )}
      {unityProvider && (
        <Unity className="unity-canvas" unityProvider={unityProvider} />
      )}
    </UnityContainer>
  );
};

export default GameEnvironment;

const LoadingScreen = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  position: absolute;
  z-index: 100;
  justify-content: center;
  align-items: center;
`;

const UnityContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  overflow: hidden;
  .unity-canvas {
    width: 100%;
    height: 100%;
  }
`;
