import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IColorOptions } from "styled";
import styled, { useTheme } from "styled-components";

interface Props {
  icon: IconDefinition;
  size?: SizeProp;
  onClick?: () => void;
  color?: "primary" | "trivia-primary";
}

const IconButton = ({ icon, onClick, size, color }: Props) => {
  const theme = useTheme();
  const colorMap = {
    primary: theme.palette.common.primary,
    "trivia-primary":
      theme.palette.common[
        "trivia-primary" as keyof typeof theme.palette.common
      ],
  };

  return (
    <StyledIconButton
      onClick={onClick}
      icon={icon}
      size={size}
      $color={colorMap.primary}
    />
  );
};

const StyledIconButton = styled(FontAwesomeIcon)<{ $color: IColorOptions }>`
  cursor: pointer;
  color: ${(props) => props.$color.text};
  &:hover {
    color: ${(props) => props.$color.dark};
  }
  &:active {
    color: ${(props) => props.$color.darker} !important;
  }

  &:focus {
    color: ${(props) => props.$color.darker} !important;
  }
  &:disabled {
    color: ${(props) => props.$color.light} !important;
  }
  transition: color 0.1s ease-in-out;
`;

export default IconButton;
