import styled from "styled-components";

const HomePageStyles = {
  Root: styled.div`
    height: 100%;
    width: 100%;
  `,
};

export default HomePageStyles;
