import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import AuthProvider from "context/Auth/AuthContext";
import App from "App";

import { ThemeProvider } from "styled-components";
import reportWebVitals from "./reportWebVitals";

import defaultTheme from "theme";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import DevWrapper from "pages/Dev/DevWrapper";
import AudioProvider from "context/AudioContext/AudioContext";
import PlayfabProvider from "context/PlayfabContext/PlayfabContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    {/* <DevWrapper> */}
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={defaultTheme}>
          <AudioProvider>
            <PlayfabProvider>
              <App />
            </PlayfabProvider>
          </AudioProvider>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
    {/* </DevWrapper>/ */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
