import { useState } from "react";
import { PowerupTypes } from "./TriviaMain";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Text from "common/Text";
import styled from "styled-components";

// "DOUBLE_POINTS",
// "LIFE_SAVER",
// "STREAK_BONUS",
// "SKIP_QUESTION",
// "FIFTY_FIFTY",

interface Props {
  powerup: { key: PowerupTypes; icon: IconProp; title: string } | null;
  shufflePowerups: () => void;
  handleNext: () => void;
}

const PowerupStep = ({ powerup, shufflePowerups, handleNext }: Props) => {
  const [hasGottedPowerup, setHasGottenPowerup] = useState(false);
  const [showContinue, setShowContinue] = useState(false);

  const onShufflePowerups = () => {
    setHasGottenPowerup(true);
    shufflePowerups();
    setTimeout(() => {
      setShowContinue(true);
    }, 3500);
  };

  return (
    <PowerupContainer>
      <Text variant="trivia" type="title">
        Click the button below to receive a powerup
      </Text>
      <div>
        {powerup && (
          <Text variant="trivia" type="subtitle">
            <FontAwesomeIcon icon={powerup.icon} fontSize={72} />
            <div>{powerup.title}</div>
          </Text>
        )}
      </div>

      <ButtonContainer>
        {!hasGottedPowerup && (
          <StyledButton variant="light" onClick={onShufflePowerups}>
            Shuffle Powerups
          </StyledButton>
        )}
        {hasGottedPowerup && showContinue && (
          <StyledButton variant="light" onClick={handleNext}>
            Start Trivia
          </StyledButton>
        )}
      </ButtonContainer>
    </PowerupContainer>
  );
};
const PowerupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const StyledButton = styled(Button)`
  min-width: 200px;
  padding: 8px 16px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 64px 0;
  min-height: 42px;
`;

export default PowerupStep;
