import HomePageStyles from "./HomePage.styles";
import { useAuth } from "context/Auth";
import GameEnvironment from "components/GameEnvironment";
import Layout from "components/Layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import WelcomeScreen from "components/WelcomeScreen";

const HomePage = () => {
  const { isAuthLoading, playerData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const localUserData = localStorage.getItem("playerData");
    if ((!playerData && !localUserData) || Object(playerData).length === 0) {
      if (!localUserData) {
        console.log("No playerData", { playerData }, Object(playerData));
        navigate("/");
        return;
      }
    }
    if (!localUserData || localUserData === "undefined") {
      navigate("/");
      return;
    }
    const parsedLocalUserData = JSON.parse(localUserData);
    if (!playerData?.avatarModelURL && !parsedLocalUserData.avatarModelURL) {
      console.log("No avatarModelURL", { playerData });
      navigate("/avatar");
      return;
    }
  }, [isAuthLoading, playerData, navigate]);

  return (
    <HomePageStyles.Root>
      <Layout>
        {/* <WelcomeScreen /> */}
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GameEnvironment />
        </div>
      </Layout>
    </HomePageStyles.Root>
  );
};

export default HomePage;
