import axios from "axios";
import { TriviaQuestion } from "./types";

const testCallAPI = async () =>
  await axios.get(`${process.env.REACT_APP_REALM_API_URL!}`);

const AnonymousPlayerRegistration = async (username: string) =>
  await axios.post(`${process.env.REACT_APP_REALM_API_URL!}/register/anon`, {
    username,
  });

const AnonymousPlayerLogin = async (username: string) =>
  await axios.post(`${process.env.REACT_APP_REALM_API_URL!}/login/anon`, {
    username,
  });

const SaveAvatarURL = async (
  avatarModelURL: string,
  _id: string,
  username: string
) =>
  await axios.post(`${process.env.REACT_APP_REALM_API_URL!}/players/avatar`, {
    _id: _id,
    username: username,
    avatarModelURL: avatarModelURL,
  });

async function fetchTriviaQuestions(
  amount: number = 10,
  {
    category,
    difficulty,
    type,
  }: { category?: number; difficulty?: string; type?: string }
): Promise<TriviaQuestion[] | null> {
  try {
    // Define the API endpoint and parameters
    const apiUrl = "https://opentdb.com/api.php";
    const params = {
      amount,
      category,
      difficulty,
      type,
    };

    // Make a GET request to the API
    const response = await axios.get(apiUrl, { params });

    // Check if the request was successful
    if (response.status === 200) {
      return response.data.results;
    } else {
      throw new Error("Failed to fetch trivia questions");
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

async function fetchTriviaCategories() {
  try {
    // Define the API endpoint and parameters
    const apiUrl = "https://opentdb.com/api_category.php";

    // Make a GET request to the API
    const response = await axios.get(apiUrl);

    // Check if the request was successful
    if (response.status === 200) {
      return response.data.trivia_categories;
    } else {
      throw new Error("Failed to fetch trivia categories");
    }
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
}
async function fetchTriviaSession() {
  try {
    // Define the API endpoint and parameters
    const apiUrl = "https://opentdb.com/api_token.php?command=request";

    // Make a GET request to the API
    const response = await axios.get(apiUrl);

    // Check if the request was successful
    if (response.status === 200) {
      console.log("Trivia Session", response);
      return response;
    } else {
      throw new Error("Failed to fetch trivia session token");
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

const actions = {
  testCallAPI,
  AnonymousPlayerRegistration,
  AnonymousPlayerLogin,
  SaveAvatarURL,
  trivia: {
    fetchTriviaQuestions,
    fetchTriviaCategories,
    fetchTriviaSession,
  },
};
export default actions;
