import { DefaultTheme } from "styled-components";
const defaultTheme: DefaultTheme = {
  borderRadius: "4px",
  palette: {
    common: {
      primary: {
        main: "#FFFFFF",
        dark: "#222831",
        light: "#222831",
        contrast: "#222831",
      },
      black: {
        main: "#111315",
        dark: "#000000",
        light: "#222831",
        contrast: "#222831",
      },
      white: {
        main: "#FFFFFF",
        dark: "#222831",
        light: "#222831",
        contrast: "#222831",
      },
      grey: {
        main: "#494F56",
        dark: "#3D4247",
        light: "#494F56",
        contrast: "#494F56",
      },
      "trivia-primary": {
        main: "#008080",
        dark: "#005959",
        darker: "#014242",
        light: "#d9dbdb",
        contrast: "#008080",
        text: "#fff",
      },
      confirm: {
        main: "#004e98",
        dark: "#004e98",
        light: "#004e98",
        contrast: "#004e98",
      },
      warning: {
        main: "#FCA311",
        dark: "#FCA311",
        light: "#FCA311",
        contrast: "#FCA311",
      },
      error: {
        main: "#a4161a",
        dark: "#a4161a",
        light: "#a4161a",
        contrast: "#a4161a",
      },
    },
    primary: {
      main: "#004e98",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#709fb0",
      contrastText: "#ffffff",
    },
    tertiary: {
      main: "#709fb0",
      contrastText: "#ffffff",
    },
    trivia: {
      main: "#004e98",
      contrastText: "#ffffff",
    },
  },
};
export default defaultTheme;
