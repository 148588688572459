import styled from "styled-components";
import HeaderIconImage from "assets/images/logos/micro-logo-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Button from "common/Button";

interface Props {
  handleShowLogout: () => void;
}

const MobileLayout = ({ handleShowLogout }: Props) => {
  const [showLayoutMenu, setShowLayoutMenu] = useState(false);
  return (
    <LayoutRoot id="toolbar">
      <div id="leftside">
        <img src={HeaderIconImage} height="28" alt="" />
      </div>
      <Button
        color="trivia-primary"
        onClick={() => setShowLayoutMenu((p) => !p)}
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>
      {showLayoutMenu && (
        <LayoutMenu>
          <Button color="trivia-primary" onClick={handleShowLogout}>
            Logout
          </Button>
        </LayoutMenu>
      )}
    </LayoutRoot>
  );
};

const LayoutRoot = styled.div`
  position: absolute;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
`;

const LayoutMenu = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
`;

export default MobileLayout;
