declare var PlayFab: PlayFabModule.IPlayFab;
declare var PlayFabClientSDK: PlayFabClientModule.IPlayFabClient;
// declare var PlayFabEconomySDK: PlayFabEconomyModule.IPlayFabEconomy;
PlayFab.settings.titleId = process.env.REACT_APP_TITLE_ID!;

export const AnonPlayfabLogin = (
  customID: string
): Promise<
  PlayFabModule.IPlayFabSuccessContainer<PlayFabClientModels.LoginResult>
> => {
  PlayFab.settings.titleId = process.env.REACT_APP_TITLE_ID!;
  var loginRequest = {
    TitleId: process.env.REACT_APP_TITLE_ID,
    CustomId: customID,
    CreateAccount: true,
  };

  return new Promise((resolve, reject) => {
    PlayFabClientSDK.LoginWithCustomID(loginRequest, (result, error) => {
      if (result !== null) {
        console.log("[PlayFab]: Success", result);
        resolve(result);
      } else if (error !== null) {
        console.error("[PlayFab] Error is: ", error);
        reject(error);
      }
    });
  });
};

export const GrantCharacterToUser = (
  CharacterName: string,
  avatarModelURL?: string
): Promise<{
  character: PlayFabModule.IPlayFabSuccessContainer<PlayFabClientModels.GrantCharacterToUserResult>;
  avatar?: PlayFabModule.IPlayFabSuccessContainer<PlayFabClientModels.UpdateCharacterDataResult>;
}> => {
  const playerCharacterTokenID = "PlayerCharacter";
  console.log("playerCharacterTokenID", playerCharacterTokenID);
  return new Promise((resolve, reject) => {
    PlayFabClientSDK.GetUserInventory({}, (inventoryResult, inventoryError) => {
      if (inventoryError) {
        console.error("inventoryError", inventoryError);
        return reject(inventoryError);
      }

      if (!inventoryResult?.data.Inventory) {
        return reject(new Error("Player Token not found in inventory"));
      }

      const hasItem = inventoryResult?.data.Inventory.some(
        (item) => item.ItemId === playerCharacterTokenID
      );

      if (!hasItem) {
        return reject(new Error("Player Token not found in inventory"));
      }

      PlayFabClientSDK.GrantCharacterToUser(
        { CharacterName, ItemId: playerCharacterTokenID },
        (newCharacterRes, error) => {
          if (newCharacterRes !== null) {
            const { CharacterId } = newCharacterRes.data;

            if (!CharacterId) {
              return reject(
                new Error(
                  `CharacterId not found in response: + ${JSON.stringify(
                    newCharacterRes
                  )}`
                )
              );
            }

            if (!avatarModelURL) {
              console.log("[PlayFab]: Success", newCharacterRes);
              return resolve({ character: newCharacterRes });
            }

            PlayFabClientSDK.UpdateCharacterData(
              {
                CharacterId,
                Data: {
                  avatarModelURL: avatarModelURL,
                },
              },
              (avatarResult, avatarError) => {
                if (avatarResult !== null) {
                  console.log("[PlayFab]: Success", avatarResult);
                  return resolve({
                    character: newCharacterRes,
                    avatar: avatarResult,
                  });
                } else if (avatarError !== null) {
                  console.error("[PlayFab] avatarError is: ", avatarError);
                  return reject(avatarError);
                }
              }
            );
          } else if (error !== null) {
            console.error("[PlayFab] Error is: ", error);
            return reject(error);
          }
        }
      );
    });
  });
};

export const GetCharacterData = (
  CharacterId: string
): Promise<
  PlayFabModule.IPlayFabSuccessContainer<PlayFabClientModels.GetCharacterDataResult>
> => {
  return new Promise((resolve, reject) => {
    PlayFabClientSDK.GetCharacterData({ CharacterId }, (result, error) => {
      if (result !== null) {
        console.log("[PlayFab]: Success", result);
        resolve(result);
      } else if (error !== null) {
        console.error("[PlayFab] Error is: ", error);
        reject(error);
      }
    });
  });
};

export const GetAllUsersCharacters = (): Promise<
  PlayFabModule.IPlayFabSuccessContainer<PlayFabClientModels.ListUsersCharactersResult>
> => {
  return new Promise((resolve, reject) => {
    PlayFabClientSDK.GetAllUsersCharacters({}, (result, error) => {
      if (result !== null) {
        console.log("[PlayFab]: Success", result);
        resolve(result);
      } else if (error !== null) {
        console.error("[PlayFab] Error is: ", error);
        reject(error);
      }
    });
  });
};
//   result: PlayFabModule.IPlayFabSuccessContainer<PlayFabClientModels.ListUsersCharactersResult>,
//   error: PlayFabModule.IPlayFabError,
//   onSuccess?: (
//     res: PlayFabModule.IPlayFabSuccessContainer<PlayFabClientModels.ListUsersCharactersResult>
//   ) => void,
//   onError?: (err: PlayFabModule.IPlayFabError) => void
// ) {
//   if (result !== null) {
//     console.log("[PlayFab]: Success", result);
//     if (onSuccess) {
//       onSuccess(result);
//     }
//   } else if (error !== null) {
//     console.error("[PlayFab] Error is: ", error);
//     if (onError) {
//       onError(error);
//     }
//   }
// };

export const UpdateCharacterData = (CharacterId: string, Data: any) => {
  PlayFabClientSDK.UpdateCharacterData({ CharacterId, Data }, GenericCallback);
};

export const GetUserInventory = () => {
  PlayFab.settings.titleId = process.env.REACT_APP_TITLE_ID!;
  // PlayFabEconomySDK.GetInventoryItems({ Count: 10 }, GenericCallback);
  PlayFabClientSDK.GetUserInventory({}, GenericCallback);
};

// need statictic name and value to update
export const UpdatePlayerStatistics = () => {
  PlayFab.settings.titleId = process.env.REACT_APP_TITLE_ID!;
  // PlayFabClientSDK.UpdatePlayerStatistics({}, GenericCallback);
};

const GenericCallback = function (result: any, error: any) {
  if (result !== null) {
    console.log("Result is: ", result);
  } else if (error !== null) {
    console.error("Error is: ", error);
  }
};
