import styled from "styled-components";
import {
  AnonPlayfabLogin,
  GetAllUsersCharacters,
  GetUserInventory,
  GrantCharacterToUser,
  UpdateCharacterData,
} from "actions/playfab";
import { Button, FormControl } from "react-bootstrap";
import { useState } from "react";

const DevPlayfab = () => {
  const [username, setUsername] = useState("test-realm-1");

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };
  const handleTestLogin = () => {
    AnonPlayfabLogin(username);
  };

  const handleGetPlayerInventory = () => {
    GetUserInventory();
  };

  const handleGetPlayerInventoryCollection = () => {
    console.log("handleGetPlayerInventoryCollection");
    GrantCharacterToUser(
      username,
      "https://models.readyplayer.me/65473d46a8f248fda2efd8b8.glb"
    )
      .then((res) => {
        console.log("Created new character", res);
        // UpdateCharacterData(username, {
        //   avatarURL: "https://models.readyplayer.me/65473d46a8f248fda2efd8b8.glb",
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSetPlayerAvatarURL = () => {
    // get player character
    UpdateCharacterData("8B69754E09BF5F53", {
      avatarModelURL:
        "https://models.readyplayer.me/65473d46a8f248fda2efd8b8.glb",
    });
  };

  const handleGetPlayerCharacters = () => {
    GetAllUsersCharacters();
  };

  return (
    <Root>
      DevPlayfab
      <ButtonContainer>
        <div>
          <FormControl
            id="inputUsername"
            placeholder="Enter a username"
            value={username}
            onChange={handleUsername}
          />
          <Button onClick={handleTestLogin}>Login Player</Button>
        </div>
        <Button onClick={handleGetPlayerInventory}>Get Inventory</Button>
        <Button onClick={handleGetPlayerInventoryCollection}>
          Create Character
        </Button>
        <Button onClick={handleSetPlayerAvatarURL}>Add Avatar URL</Button>
        <Button onClick={handleGetPlayerCharacters}>Get All Characters</Button>
      </ButtonContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100vh;
  width: 100%;
  padding: 32px;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export default DevPlayfab;
