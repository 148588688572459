import { Button, Spinner } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

import { useAuth } from "context/Auth";

import AuthPageStyles from "./AuthPage.styles";

import HeroImage from "assets/images/Avatars/groups/avatars-group-1.png";
import HeroMobileImage from "assets/images/Avatars/groups/avatars-group-5.png";
import PoweredByImage from "assets/images/poweredby/light-dark-bg.png";
import { useLessthanBreakpoint } from "hooks";
import LoginForm from "./components/LoginForm";

const AuthPage = () => {
  const { isAuthLoading } = useAuth();
  const navigate = useNavigate();
  const isMediumScreen = useLessthanBreakpoint("md");

  return (
    <AuthPageStyles.Root>
      {/* Left side of landing page */}
      <AuthPageStyles.HeroContainer>
        <AuthPageStyles.TextContainer>
          <AuthPageStyles.TitleText>&#x03BC;Realms</AuthPageStyles.TitleText>
          <AuthPageStyles.ExplainationText>
            &#x03BC; comes from the Greek word mikrós, meaning "small".
          </AuthPageStyles.ExplainationText>
        </AuthPageStyles.TextContainer>
        <AuthPageStyles.HeroImageContainer>
          {isMediumScreen ? (
            <AuthPageStyles.HeroMobileImage
              src={HeroMobileImage}
              alt="A group of 4 vitual avatars"
            />
          ) : (
            <AuthPageStyles.HeroImage
              src={HeroImage}
              alt="A group of 4 vitual avatars"
            />
          )}
        </AuthPageStyles.HeroImageContainer>
        <div>
          <AuthPageStyles.PoweredByImage src={PoweredByImage} />
        </div>
      </AuthPageStyles.HeroContainer>

      {/* Overlay for Login Container */}
      {isAuthLoading && (
        <AuthPageStyles.LoadingOverlay>
          <Spinner
            style={{ width: "80px", height: "80px" }}
            animation="border"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </AuthPageStyles.LoadingOverlay>
      )}
      {/* Right side of landing page */}
      <AuthPageStyles.FormContainer>
        <div>
          <div style={{ textAlign: "center", marginBottom: "12px" }}>
            Click this button to try out Trivia Time!
          </div>
          <Button onClick={() => navigate("/trivia")} style={{ width: "100%" }}>
            Go To Trivia
          </Button>
        </div>
        <LoginForm />
        <AuthPageStyles.FormFooter>
          Dont have an account?
          <NavLink to={"/"} replace>
            Click here
          </NavLink>
        </AuthPageStyles.FormFooter>
      </AuthPageStyles.FormContainer>
    </AuthPageStyles.Root>
  );
};

export default AuthPage;
