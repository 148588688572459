import React, { useContext, createContext, useState } from "react";

export const PlayfabContext = createContext<any>({});

interface Props {
  children: React.ReactNode;
}

// import { PlayFabEconomySDK } from "plugins/PlayFab";

// var PlayFabClientSDK: any;

// export function AnonPlayfabLogin(username: string) {
//   var loginRequest = {
//     // Currently, you need to look up the correct format for this object in the API-docs:
//     // https://api.playfab.com/Documentation/Client/method/LoginWithCustomID
//     TitleId: process.env.REACT_APP_TITLE_ID!,
//     CustomId: username,
//     CreateAccount: false,
//   };

//   PlayFabClientSDK.LoginWithCustomID(loginRequest, LoginCallback);
// }

// var LoginCallback = function (result: any, error: any) {
//   if (result !== null) {
//   } else if (error !== null) {
//   }
// };

// // This is a utility function we haven't put into the core SDK yet.  Feel free to use it.
// function CompileErrorReport(error: any) {
//   if (error === null) return "";
//   var fullErrors = error.errorMessage;
//   for (var paramName in error.errorDetails)
//     for (var msgIdx in error.errorDetails[paramName])
//       fullErrors +=
//         "\n" + paramName + ": " + error.errorDetails[paramName][msgIdx];
//   return fullErrors;
// }

const PlayfabProvider: React.FC<Props> = ({ children }) => {
  const [applicationState, setApplicationState] = useState<
    "login" | "select" | "fight" | "end"
  >("login");

  // useEffect(() => {
  //   if (isAuthorized) {
  //     setApplicationState("select");
  //   } else {
  //     setApplicationState("login");
  //   }
  // }, [isAuthorized]);

  return (
    <PlayfabContext.Provider
      value={{
        applicationState,
        setApplicationState,
      }}
    >
      {children}
    </PlayfabContext.Provider>
  );
};

export const useApp = () => {
  return useContext(PlayfabContext);
};
export default PlayfabProvider;
