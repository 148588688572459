import React from "react";
import { TriviaCategoryProps } from "../TriviaMain";
import styled from "styled-components";
import { Button } from "react-bootstrap";

interface Props {
  categories: TriviaCategoryProps[];
  setSelectedCategory: (category: TriviaCategoryProps) => void;
}
const Categories = ({ categories, setSelectedCategory }: Props) => {
  return (
    <CategoryContainer>
      <CategoryTitle>Select a Trivia Category</CategoryTitle>
      <CategoryList>
        {categories.length > 0 &&
          categories.map((category) => {
            return (
              <div key={"category-" + category.id}>
                <CategoryButton
                  variant="light"
                  onClick={() => setSelectedCategory(category)}
                >
                  {category.name}
                </CategoryButton>
              </div>
            );
          })}
      </CategoryList>
    </CategoryContainer>
  );
};

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  align-items: center;
  width: 100%;
  padding-bottom: 64px;
`;

const CategoryTitle = styled.div`
  margin: 24px 0 48px;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 22px;
    margin: 0px 0 32px;
  }
`;

const CategoryList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 8px 12px;
  width: 100%;
  max-width: 700px;
`;

const CategoryButton = styled(Button)`
  height: 100px;
  width: 320px;
  font-size: 22px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 16px;
    height: 64px;
  }
`;

export default Categories;
