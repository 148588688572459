export function areObjectsEqual(obj1: any, obj2: any) {
  if (!obj1 && !obj2) return true;
  if (!obj1 || !obj2) return false;

  const obj1Props = Object.getOwnPropertyNames(obj1);
  const obj2Props = Object.getOwnPropertyNames(obj2);

  if (obj1Props.length !== obj2Props.length) {
    return false;
  }

  for (const prop of obj1Props) {
    if (obj1[prop] !== obj2[prop]) {
      return false;
    }
  }

  return true;
}

export function isValidEmail(stringToTest: string) {
  const result = String(stringToTest)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  return !!result;
}

export function decodeHtmlEntities(input: string) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}
