import { useState, useEffect, useCallback } from "react";
import { ProgressBar } from "react-bootstrap";
import styled from "styled-components";

type CountdownProps = {
  onTimerExpired: () => void;
  timerCancelled: boolean;
  timerSeconds?: number;
};

function Countdown({
  onTimerExpired,
  timerCancelled,
  timerSeconds: startTime,
}: CountdownProps) {
  const DEFAULT_TIME = 12;
  const [timerRunning, setTimerRunning] = useState(true);
  const [countdown, setCountdown] = useState(startTime || DEFAULT_TIME);

  const handleTimeout = useCallback(() => {
    setTimerRunning(false);
    onTimerExpired();
  }, [onTimerExpired]);

  useEffect(() => {
    let timer: any;

    if (timerRunning) {
      timer = setTimeout(() => {
        handleTimeout();
      }, countdown * 1000); // Multiply by 1000 to convert seconds to milliseconds
    }

    return () => clearTimeout(timer);
  }, [timerRunning, countdown, handleTimeout]);

  useEffect(() => {
    if (timerCancelled) {
      setTimerRunning(false);
      handleTimeout();
    }
  }, [timerCancelled, handleTimeout]);

  useEffect(() => {
    if (timerRunning) {
      const interval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000); // Update countdown every second

      return () => clearInterval(interval);
    }
  }, [timerRunning, countdown]);

  return (
    <TimerContainer>
      <TimerText>Time left: {countdown} seconds</TimerText>
      <TimerBar
        variant="dark"
        now={(countdown / (startTime || DEFAULT_TIME)) * 100}
      />
    </TimerContainer>
  );
}

const TimerContainer = styled.div`
  width: 80%;
`;
const TimerText = styled.div`
  text-align: center;
`;

const TimerBar = styled(ProgressBar)`
  margin: 16px 0 20px;
  @media (max-width: 768px) {
    margin: 8px 0 16px;
  }
`;

export default Countdown;
